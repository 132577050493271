import type { ControllerProps, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { MaskedTextFieldProps } from '@vtbl/mui-kit';
import { MaskedTextField } from '@vtbl/mui-kit';

import { minPhoneLength, required } from '~/validation/rules';

export type PhoneInputControllerProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> & { MaskedTextFieldProps?: MaskedTextFieldProps };

export const PhoneInputController = <T extends FieldValues>({
  MaskedTextFieldProps = {},
  ...props
}: PhoneInputControllerProps<T>) => {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <MaskedTextField
          size='small'
          maskName='phone'
          {...field}
          {...MaskedTextFieldProps}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
};

export const PhoneInputControllerWithRules = <T extends FieldValues>({
  rules = {},
  ...props
}: PhoneInputControllerProps<T>) => {
  return (
    <PhoneInputController rules={{ required, minLength: minPhoneLength, ...rules }} {...props} />
  );
};
