import { useMemo } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Stack, Tooltip } from '@mui/material';

import { useBreakpoints, useCopyToClipboard } from '~/hooks';
import { ECarTagLabel, TCar } from '~/types';
import { getCarOptions, separateThousands } from '~/utils';

import { OldPrice, Options, Price } from '../../CarInfo.styles';
import { CarDescription } from '../CarDescription';
import { Vin } from './PurchaseInfo.styles';

export const PurchaseInfo = ({ car }: { car: TCar }) => {
  const copyToClipboard = useCopyToClipboard();
  const price = car.pokupkaPrice != car.fullPrice ? car.pokupkaPrice : car.fullPrice;
  const oldPrice = car.pokupkaPrice != car.fullPrice && car.fullPrice;
  const isUsed = car.owners_number > 0;
  const { isDesktop } = useBreakpoints();

  const carOptions = useMemo(
    () =>
      getCarOptions(
        isUsed
          ? [
              car.registry_year.toString(),
              car.mileage ? separateThousands(car.mileage) + ' км' : '',
              car.engine + ' л.',
              car.gearbox,
              car.drive,
              car.color,
            ]
          : [
              car.engine_type,
              car.power_hp + ' л.с.',
              car.engine + ' л.',
              car.gearbox,
              car.drive,
              car.color,
            ],
      ),
    [car],
  );

  const carDescription = useMemo(() => {
    if (isUsed) {
      return {
        items: [
          'Куплен и обслуживался у официального дилера',
          car.owners_number === 1 && 'Один владелец по ПТС',
          'Оригинальный пробег',
          'Гарантия юридической чистоты',
          'Специальные условия на кредит и страхование от ВТБ Авто',
        ],
      };
    }

    if (car.newTags.find(({ label }) => label === ECarTagLabel.PartPayments)) {
      return {
        header: (
          <Stack direction='row' alignItems='center' flexWrap='wrap'>
            <span>Новые авто с оплатой частями{isDesktop ? '' : ' 🚀'}</span>
            <Tooltip title='Оплата частями - это ежемесячная оплата арендных платежей за пользование автомобилем в течение срока аренды (3 месяца) по договору аренды (далее - ДА). После окончания срока аренды автомобиль может быть приобретен в собственность по стоимости автомобиля, предусмотренной в ДА. Для передачи автомобиля по ДА нужно оплатить обеспечительный платеж.'>
              <IconButton size='small'>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        items: [
          'Сниженные цены. Без скрытых платежей и комиссий',
          'Не нужно платить всю сумму сразу. Всего 20 000 ₽ и авто у вас',
          'Полное страхование, налоги и постановка на учет включены в первые 3 месяца  арендной платы',
          'Гарантия от производителя, скидки на обслуживание и страхование',
        ],
      };
    }

    if (car.newTags.find(({ label }) => label === ECarTagLabel.Foreign)) {
      return {
        items: [
          'Все автомобили растаможены и привезены в соответствии с требованиями законодательства Российской Федерации',
          'Никаких скрытых платежей и комиссий',
          'Простая и безопасная сделка',
          'Бесплатная доставка авто до дома ',
        ],
      };
    }

    return null;
  }, [car, isDesktop]);

  return (
    <Stack>
      <Options>{carOptions}</Options>

      {isUsed && (
        <Vin>
          <span>VIN: </span>
          <span>{car.vin}</span>
          <Tooltip title='Скопировать текст'>
            <IconButton size='small' onClick={() => copyToClipboard(car.vin, 'Текст скопирован.')}>
              <ContentCopyIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Vin>
      )}

      <Stack rowGap='4px' marginBottom={{ xs: '16px', lg: '24px' }}>
        {price && <Price>{`${separateThousands(price)} ₽`}</Price>}
        {oldPrice && <OldPrice>{`${separateThousands(oldPrice)} ₽`}</OldPrice>}
      </Stack>

      {carDescription && (
        <CarDescription header={carDescription?.header} items={carDescription?.items} />
      )}
    </Stack>
  );
};
