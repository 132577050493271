import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { lighten, ThemeOptions } from "@mui/material";
import { css } from "@emotion/react";

export const autocompleteOptions: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => css`
          && .MuiAutocomplete-clearIndicator,
          && .MuiAutocomplete-popupIndicator {
            color: ${theme.palette.vtbl.HawaiianSky};
          }

          && .MuiInputBase-root {
            display: flex;
            flex-wrap: nowrap;

            &:hover,
            &.Mui-focused {
              padding-right: 110px;
            }
          }

          && .MuiAutocomplete-tagSizeSmall {
            /* TODO переопределить margin для кейсов где чипов больше чем 1 */
            flex-grow: 0;
            margin: 0;
            background: ${theme.palette.grey[100]};
            color: ${theme.palette.vtbAuto.DarkGray};

            .MuiChip-deleteIcon {
              color: ${lighten(theme.palette.vtbAuto.PrimaryGray, 0.7)};
            }
          }
        `,
        input: ({ theme }) => css`
          &::placeholder {
            color: ${theme.palette.vtbAuto.DarkGray};
            opacity: 1;
          }
        `,
        option: ({ theme }) => css`
          & {
            color: ${theme.palette.vtbAuto.PrimaryGray};
            line-height: 18px;
            letter-spacing: -0.5px;
            padding: 14px 16px;
          }
        `,
      },
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
      },
    },
  },
};
