import { PropsWithChildren } from "react";

import { Footer, Header, ScrollToTop } from "./components";
import { LayoutWrapper } from "./Layout.styles";
import { Container } from "@mui/material";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <LayoutWrapper>
      <ScrollToTop />
      <Header />
      <Container>{children}</Container>
      <Footer />
    </LayoutWrapper>
  );
};
