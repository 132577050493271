import ReactDOM from "react-dom/client";

import { App } from "./app/App";

if (process.env.MOCK === "mock") {
  // см. /mocks/handlers и setupMiddlewares в webpack.dev.server.js
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./mocks/browser");
  worker.start();
}

const rootElement = document.getElementById("root")!;

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <App />);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
