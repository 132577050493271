import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/** Подписка на смену роута и скролл страницы наверх */
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
