export const interceptor = (config) => {
  return {
    ...config,
    auth: {
      username: 'api',
      password: 9357,
    },
    headers: {
      ...config.headers,
    },
  };
};
