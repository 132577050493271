import { Box } from '@mui/material';

import styled from '@emotion/styled';
import NoCar from 'assets/images/no-car.webp';

export const BOTTOM_SLIDER_IMAGE_WIDTH = '120px';
export const BOTTOM_SLIDER_IMAGE_HEIGHT = '80px';

export const TOP_SLIDER_HEIGHT_BREAKPOINTS = {
  sx: '218px',
  md: '400px',
  lg: '500px',
};

export const SliderContainer = styled(Box)`
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  position: relative;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 100%;
    margin-left: 0;
  }
`;

export const TopSliderContainer = styled(SliderContainer, {
  shouldForwardProp: (props) => props !== 'currentDot',
})`
  .slick-dots {
    position: relative;
    bottom: 8px;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none !important;
  }
`;

export const TopSlideImage = styled(Box)<{ url: string }>`
  width: 100%;
  height: ${TOP_SLIDER_HEIGHT_BREAKPOINTS.sx};
  background:
    url(${({ url }) => url}) no-repeat center / cover,
    url(${NoCar}) no-repeat center / cover;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${TOP_SLIDER_HEIGHT_BREAKPOINTS.md};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: ${TOP_SLIDER_HEIGHT_BREAKPOINTS.lg};
  }
`;

export const BottomSlideImage = styled(Box)<{ url: string }>`
  width: ${BOTTOM_SLIDER_IMAGE_WIDTH};
  height: ${BOTTOM_SLIDER_IMAGE_HEIGHT};
  border-radius: 10px;
  margin-right: 5px;
  background: url(${({url}) => url}) no-repeat center / cover,
  url(${NoCar}) no-repeat center / cover;

  .slick-current & {
    border: 2px solid ${({theme}) => theme.palette.vtbAuto.BluePrimary};
  }
`;
