import type { Middleware, SWRHook } from 'swr';

/** 
 * Intercepts all swr requests and rewrites isValidating logic as if there's no data
 * */
export const swrMiddleware: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const { data, error, ...rest } = useSWRNext(key, fetcher, config);

    return rest.isValidating
      ? { data: undefined, error: undefined, ...rest }
      : { data, error, ...rest };
  };
};
