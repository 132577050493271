import type { ButtonProps } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import type { SnackbarProps } from '@mui/material/Snackbar';

export const CloseableSnackbar = ({
  message,
  action,
  SnackbarProps,
}: {
  message?: string;
  action?: string;
  ButtonProps?: Partial<ButtonProps>;
  SnackbarProps: Partial<SnackbarProps>;
  customParameters: { autoHideDuration?: number };
}) => {
  return (
    <Snackbar {...SnackbarProps} message={message}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Alert icon={action === 'success' ? false : undefined} severity={action as any}>
        {message}
      </Alert>
    </Snackbar>
  );
};
