import { useMemo, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CarNew from "assets/images/car-new.webp";
import CarOrder from "assets/images/car-order.webp";
import TwoCars from "assets/images/two-cars.webp";
import CarUsed from "assets/images/car-used.webp";
import { useBreakpoints } from "hooks";

import { PriceCalculationExample } from "./components";
import {
  AccordionStyled,
  Card,
  Description,
  DetailsText,
  ImageContainer,
  ImageWrapper,
  TextContainer,
  Title,
} from "./PurchaseTiles.styles";

export const PurchaseTiles = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const [openCardIndex, setCardPileIndex] = useState<number | undefined>(0);

  const items = useMemo(
    () => [
      {
        title: "Новые авто с оплатой частями 🚀",
        description:
          "Покупка с фиксированными платежами в первые 3 месяца и с доставкой на дом",
        imageUrl: TwoCars,
        content: (
          <Stack direction="column" rowGap="32px">
            <Stack direction="column" rowGap="12px">
              <Stack direction="row" alignItems="center">
                <Title>Почему оплата частями — это выгодно?</Title>
                <Tooltip title="Оплата частями - это ежемесячная оплата арендных платежей за пользование автомобилем в течение срока аренды (3 месяца) по договору аренды (далее - ДА). После окончания срока аренды автомобиль может быть приобретен в собственность по стоимости автомобиля, предусмотренной в ДА. Для передачи автомобиля по ДА нужно оплатить обеспечительный платеж.">
                  <IconButton
                    size="small"
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Description>
                — Сниженные цены. Без скрытых платежей и комиссий
                <br />
                — Не нужно платить всю сумму сразу. Всего 20 000 ₽ и авто у вас
                <br />
                — Полное страхование, налоги и постановка на учет включены в
                первые 3 месяца арендной платы
                <br />— Гарантия от производителя, скидки на обслуживание и
                страхование
              </Description>
            </Stack>

            <Stack direction="column" rowGap="12px">
              <Title>Как работает оплата частями</Title>
              <Description>
                — Первые три месяца авто находится в аренде
                <br />
                — Арендные платежи идут в счет стоимости автомобиля
                <br />— После аренды авто выкупается удобным способом
              </Description>
            </Stack>
          </Stack>
        ),
      },
      {
        title: "В наличии из-за рубежа",
        description:
          "Европейские, японские и другие зарубежные марки на выгодных условиях",
        imageUrl: CarNew,
        content: (
          <Stack direction="column" rowGap="12px">
            <Title>
              Авто популярных зарубежных марок уже в наличии в собственном парке
            </Title>
            <Description>
              — Все автомобили растаможены и привезены в соответствии с
              требованиями законодательства Российской Федерации
              <br />
              — Никаких скрытых платежей и комиссий
              <br />
              — Простая и безопасная сделка
              <br />
              — Автокредитование на специальных условиях
              <br />— Бесплатная доставка авто до дома
            </Description>
          </Stack>
        ),
      },
      {
        title: "С пробегом",
        description: `Большой выбор популярных марок`,
        imageUrl: CarUsed,
        content: (
          <Stack direction="column" rowGap="12px">
            <Title>Собственный автопарк надежных и проверенных авто</Title>
            <Description>
              — 100% прозрачная история авто с проверкой по VIN-коду
              <br />
              — Все авто куплены и обслуживались у официальных дилеров
              <br />— Полностью проверенный автомобиль с гарантией отличного
              состояния
            </Description>
          </Stack>
        ),
      },
      {
        title: "Под заказ",
        description: `Нужное вам авто напрямую от производителя`,
        imageUrl: CarOrder,
        content: (
          <Stack direction="column" rowGap="12px">
            <Title>Привезем авто по индивидуальному запросу</Title>
            <Description>
              — Специальные цены от официальных производителей
              <br />
              — Экспресс-доставка
              <br />— Официальная гарантия от производителя
            </Description>
          </Stack>
        ),
      },
    ],
    []
  );

  const openedItem =
    typeof openCardIndex === "number" ? items[openCardIndex] : undefined;

  return (
    <section>
      {isDesktop && (
        <>
          <Stack
            direction="row"
            alignItems="stretch"
            columnGap="32px"
            marginBottom="32px"
          >
            {items.map((item, index) => {
              const isOpen = index === openCardIndex;

              return (
                <Card
                  key={index}
                  isOpen={isOpen}
                  onClick={() => setCardPileIndex(isOpen ? undefined : index)}
                >
                  <Stack direction="column" rowGap="12px" marginBottom="12px">
                    <Title>{item.title}</Title>
                    <Description>{item.description}</Description>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <DetailsText>Подробнее</DetailsText>
                    <DetailsText>{isOpen ? "↓" : "→"}</DetailsText>
                  </Stack>
                </Card>
              );
            })}
          </Stack>

          {openedItem && (
            <Stack direction="row" alignItems="center">
              <ImageContainer>
                <ImageWrapper src={openedItem.imageUrl} />
              </ImageContainer>
              <TextContainer>{openedItem.content}</TextContainer>
            </Stack>
          )}

          {openCardIndex === 0 && (
            <Box marginTop="32px">
              <PriceCalculationExample />
            </Box>
          )}
        </>
      )}

      {(isTablet || isMobile) && (
        <>
          <Stack direction="column" alignItems="stretch" rowGap="8px">
            {items.map((item, index) => (
              <AccordionStyled
                key={index}
                elevation={0}
                disableGutters
                square
                defaultExpanded={index === 0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Title>{item.title}</Title>
                </AccordionSummary>

                <AccordionDetails>
                  <Description marginBottom="8px">
                    {item.description}
                  </Description>

                  <Stack
                    direction={isMobile ? "column" : "row"}
                    alignItems="top"
                    marginTop={isMobile ? "8px" : 0}
                  >
                    <ImageContainer>
                      <ImageWrapper src={item.imageUrl} />
                    </ImageContainer>
                    <Box marginLeft={isTablet ? "16px" : 0}>{item.content}</Box>
                  </Stack>

                  {index === 0 && <PriceCalculationExample />}
                </AccordionDetails>
              </AccordionStyled>
            ))}
          </Stack>
        </>
      )}
    </section>
  );
};
