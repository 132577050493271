import { Typography } from "@mui/material";

import styled from "@emotion/styled";

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
  margin-bottom: 16px;
`;
