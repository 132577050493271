import { FC, useMemo } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Docs from "assets/images/docs.webp";
import Wheel from "assets/images/wheel.webp";
import LittleCar from "assets/images/little-car.webp";
import Cone from "assets/images/cone.webp";
import Family from "assets/images/family.webp";
import Travel from "assets/images/travel.webp";
import { useBreakpoints } from "hooks";

import {
  AccordionStyled,
  Description,
  ImageWrapper,
  Title,
} from "./SubscriptionTiles.styles";

export const SubscriptionTiles: FC = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const items = useMemo(
    () => [
      {
        title: "Легкое оформление",
        description:
          "Мы оплатим госпошлину за постановку на учет, транспортный и все остальные налоги",
        imageUrl: Docs,
      },
      {
        title: "Полное обслуживание",
        description:
          "Возьмем на себя страхование, ТО, ремонты, смену шин и другие заботы. На вас только топливо и мойки",
        imageUrl: Wheel,
      },
      {
        title: "Консьерж-сервис",
        description: "Заберем авто в сервис и доставим в удобное для вас место",
        imageUrl: LittleCar,
      },
      {
        title: "Помощь на дороге",
        description:
          "Эвакуатор, помощь при ДТП, подвоз топлива, зарядка аккумулятора и другое",
        imageUrl: Cone,
      },
      {
        title: "Семейный доступ",
        description:
          "Вы можете допустить к управлению своих близких. Данная опция бесплатна, для этого необходимо обратиться к менеджеру",
        imageUrl: Family,
      },
      {
        title: (
          <Stack direction="row" alignItems="center" columnGap="4px">
            <Title>Без границ</Title>
            <Tooltip title="На территориях, по которым есть страховое покрытие (кроме ЛНР, ДНР)">
              <IconButton size="small">
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        description:
          "Мы не ограничиваем ваши поездки чертой города, вы можете путешествовать по всей России",
        imageUrl: Travel,
      },
    ],
    []
  );

  const desktopOrderSortingArr = [0, 3, 1, 4, 2, 5];

  return (
    <section>
      {(isDesktop || isTablet) && (
        <Stack
          direction="row"
          columnGap={isDesktop ? "32px" : "16px"}
          rowGap={isDesktop ? "16px" : "24px"}
          flexWrap="wrap"
        >
          {items.map((_, index) => {
            const itemIndex = isDesktop ? desktopOrderSortingArr[index] : index;
            const item = items[itemIndex];

            return (
              <Stack
                direction="row"
                alignItems={isDesktop ? "center" : "top"}
                columnGap="8px"
                key={index}
                flex="1 0 calc(50% - 16px)"
              >
                <ImageWrapper src={item.imageUrl} />
                <Stack direction="column" rowGap="8px">
                  <Title>{item.title}</Title>
                  <Description>{item.description}</Description>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}

      {isMobile && (
        <Stack direction="column" alignItems="stretch" rowGap="8px">
          {items.map((item, index) => (
            <AccordionStyled key={index} elevation={0} disableGutters square>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" alignItems="center" columnGap="8px">
                  <ImageWrapper src={item.imageUrl} />
                  <Title>{item.title}</Title>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Description marginTop="4px">{item.description}</Description>
              </AccordionDetails>
            </AccordionStyled>
          ))}
        </Stack>
      )}
    </section>
  );
};
