import { Typography, styled } from '@mui/material';

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 1;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  letter-spacing: -1px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 32px;
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 0;
  }
`;

export const Options = styled(Typography)`
  font-size: 14px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbl.Grey};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
    letter-spacing: -0.5px;
  }
`;

export const Price = styled(Typography)`
  line-height: 1;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.vtbAuto.AccentCobalt};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 38px;
    letter-spacing: -0.5px;
  }
`;

export const OldPrice = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
  text-decoration: line-through;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    letter-spacing: -1px;
  }
`;
