/** Преобразует массив строк в строку с разделителями " / " с возможностью переноса по индексу */
export const getCarOptions = (options: string[], brIdx = 0) => {
  const filteredOptions = options.filter(Boolean);

  return (
    brIdx
      ? [...filteredOptions.slice(0, brIdx), <br key={brIdx} />, ...filteredOptions.slice(brIdx)]
      : filteredOptions
  ).map((value, idx, arr) =>
    typeof value === 'string' ? (
      <span key={idx}>{value + (idx === arr.length - 1 ? '' : ' / ')}</span>
    ) : (
      value
    ),
  );
};
