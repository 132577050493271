import { FC, StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline } from '@mui/material';

import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { SWRConfig } from 'swr';

import { CloseableSnackbar } from './CloseableSnackbar';
import { Layout } from './Layout';
import { Routing } from './Routing';
import { VtbAutoFonts } from './fonts/VtblFonts';
import { VtbAutoThemeProvider } from './theme/VtbAutoThemeProvider';

export const App: FC = () => {
  useEffect(() => {
    console.log(`${process.env.APP_NAME} v${process.env.APP_VERSION}`);
  }, []);

  return (
    <StrictMode>
      {/* Add theme */}
      <VtbAutoThemeProvider>
        {/* Add fonts */}
        <VtbAutoFonts />
        {/* Like normalize.css */}
        <CssBaseline />
        {/* Add snackbar provider */}
        <SnackbarProvider
          SnackbarComponent={CloseableSnackbar}
          SnackbarProps={{
            autoHideDuration: 4000,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
        >
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <SWRConfig value={{ shouldRetryOnError: false, revalidateOnFocus: false }}>
              <Layout>
                <Routing />
              </Layout>
            </SWRConfig>
          </BrowserRouter>
        </SnackbarProvider>
      </VtbAutoThemeProvider>
    </StrictMode>
  );
};
