import { Skeleton, Stack } from '@mui/material';

export const CarInfoSkeleton = () => {
  return (
    <Stack rowGap={'8px'} sx={{ marginTop: { xs: '8px', lg: '0px' } }} width={'100%'}>
      <Skeleton
        variant='rounded'
        sx={{
          width: { xs: '50%', lg: '100%' },
        }}
        height={'32px'}
      />
      <Skeleton variant='rounded' height={'18px'} width={'296px'} sx={{ mt: '8px' }} />
      <Skeleton variant='rounded' height={'34px'} width={'212px'} sx={{ mt: '8px' }} />
      <Skeleton variant='rounded' height={'52px'} width={'324px'} sx={{ mt: '32px' }} />
      <Skeleton variant='rounded' height={'37px'} width={'100%'} sx={{ mt: '16px' }} />
      <Skeleton variant='rounded' height={'37px'} width={'100%'} sx={{ mt: '16px' }} />
      <Skeleton variant='rounded' height={'37px'} width={'100%'} sx={{ mt: '16px' }} />
      <Skeleton variant='rounded' height={'60px'} width={'100%'} sx={{ mt: '32px' }} />
    </Stack>
  );
};
