import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { Param, QuerySearchParams } from '~/services/constants';

export const useQueryStringParam = (name: string, defaultValue: string) => {
  const [params] = useSearchParams();

  return params.get(name) || defaultValue;
};

export const useQueryNumberParam = (name: string, defaultValue: number) => {
  const [params] = useSearchParams();

  return Number(params.get(name)) || defaultValue;
};

export const useQueryArrayParam = (name: string, defaultValue: string[]) => {
  const [params] = useSearchParams();

  return params.getAll(name) || defaultValue;
};

const setQueryArrayParam = (params: URLSearchParams, paramName: Param, paramValues: string[]) => {
  params.delete(paramName);

  if (paramValues?.length) {
    paramValues.forEach((value) => params.append(paramName, value));
  }

  return params;
};

const setQueryParam = (params: URLSearchParams, paramName: Param, paramValue: string) => {
  params.delete(paramName);

  if (paramValue) {
    params.set(paramName, paramValue);
  }

  return params;
};

export const setQueryParams = (
  params: URLSearchParams,
  searchParams: Partial<QuerySearchParams>,
) => {
  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      setQueryArrayParam(params, key as Param, value);
    } else {
      setQueryParam(params, key as Param, value);
    }
  });

  return params;
};

export const useSetQueryParams = () => {
  const [, setParams] = useSearchParams();
  return useCallback(
    (params: Partial<QuerySearchParams>) => {
      setParams((prev) => {
        return setQueryParams(prev, params);
      });
    },
    [setParams],
  );
};
