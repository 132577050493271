import { Box, Button, Typography } from '@mui/material';

import styled from '@emotion/styled';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 64px;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueDark};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.75px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
`;

export const LoadMoreButton = styled(Button)`
  margin-top: 16px;

  &:not(:hover, :focus) {
    color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  }

  && {
    background: ${({ theme }) => theme.palette.vtbl.GreyBlue};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 16px auto auto;
    width: 230px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 342px;
    height: 48px;
    margin-top: 28px;
  }
`;
