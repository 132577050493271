import type { ControllerProps, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { CheckboxProps, FormControlLabelProps, TypographyProps } from '@mui/material';
import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import { Typography } from '@mui/material';

import styled from '@emotion/styled';

import { useBreakpoints } from '~/hooks/useBreakpoints';

export const CheckboxLabel = styled(Typography)`
  padding: 9px 0;
  user-select: none;
  font-size: 12px;
  line-height: 1.1;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 10px;
    line-height: 12px;

    & > * {
      display: block;
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: -8px;
    justify-content: flex-start;
  }

  & .MuiFormControlLabel-label {
    order: -1;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      order: 0;
    }
  }
`;

export type CheckboxWithLabelControllerProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> & {
  CheckboxProps?: Omit<CheckboxProps, 'size'>;
  LabelProps?: TypographyProps;
} & Pick<FormControlLabelProps, 'label'>;

export const CheckboxWithLabelController = <T extends FieldValues>({
  CheckboxProps = {},
  LabelProps = {},
  label,
  ...props
}: CheckboxWithLabelControllerProps<T>) => {
  const { isMobile } = useBreakpoints();

  const SwitchComponent = !isMobile ? Checkbox : Switch;
  return (
    <StyledFormControlLabel
      control={
        <Controller
          {...props}
          render={({ field }) => {
            return <SwitchComponent checked={field.value} {...field} {...CheckboxProps} />;
          }}
        />
      }
      label={<CheckboxLabel {...LabelProps}>{label}</CheckboxLabel>}
    />
  );
};
