import { FC } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';

import { LinkProps } from '@mui/material';

import styled from '@emotion/styled';

import { SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY } from '~/consts';
import { useIsSubscription } from '~/hooks';

import { routeConfig } from '~/app/routeConfig';

const StyledLink = styled(Link)<LinkProps & RouterLinkProps>`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
  display: none;
  text-decoration: none;
  margin-top: 14px;
  margin-bottom: -18px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: block;
  }
`;

export const GoToCatalogueLInk: FC = () => {
  const isSubscription = useIsSubscription();
  const catalogueQuery = sessionStorage.getItem(SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY) || '';

  return (
    <StyledLink
      to={`/${
        isSubscription ? routeConfig.SubscriptionCatalogue.path : routeConfig.PurchaseCatalogue.path
      }${catalogueQuery}`}
    >
      В каталог →
    </StyledLink>
  );
};
