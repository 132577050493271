import { Fragment, useLayoutEffect, useMemo, useState } from 'react';

import { TCar } from 'types';

import { useBreakpoints } from '~/hooks/useBreakpoints';

import { CardsSceleton } from '../Sceletons';
import { CarRequestCard } from './CarRequestCard';
import { Card } from './Card/Card';
import { Grid } from './Cards.styles';
import { GridSettings, minGridWidth } from './constants';

export type CardsProps = {
  cars?: TCar[];
  grid?: Partial<typeof GridSettings>;
  isLoading?: boolean;
  isRelated?: boolean;
  showRequestCard?: boolean;
  tagToShow?: string;
  showSelfTag?: boolean;
};

export const Cards = ({
  cars = [],
  grid,
  isLoading,
  isRelated = false,
  showRequestCard = false,
  tagToShow,
  showSelfTag,
}: CardsProps) => {
  const { isTablet, isDesktop } = useBreakpoints();
  const gridSettings = useMemo(() => ({ ...GridSettings, ...(grid || {}) }), [grid]);

  const [gridNode, setGridNode] = useState<HTMLElement | null>(null);
  const [width, setWidth] = useState(minGridWidth);
  const [beforeRequestCardNode, setBeforeRequestCardNode] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (!gridNode) {
      return;
    }

    const callback = () => {
      setWidth(gridNode.clientWidth);
    };
    const observer = new ResizeObserver(callback);
    observer.observe(gridNode);

    return () => observer.disconnect();
  }, [gridNode]);

  const columnWidth = useMemo(() => {
    const getWidth = (columnsGap: number, columnsCount: number) => {
      return (width - columnsGap * (columnsCount - 1)) / columnsCount;
    };
    if (isTablet) {
      return getWidth(gridSettings.tablet.gap, gridSettings.tablet.columns);
    }

    if (isDesktop) {
      return getWidth(gridSettings.desktop.gap, gridSettings.desktop.columns);
    }

    return getWidth(gridSettings.mobile.gap, gridSettings.mobile.columns);
  }, [
    isTablet,
    isDesktop,
    width,
    gridSettings.tablet.gap,
    gridSettings.tablet.columns,
    gridSettings.desktop.gap,
    gridSettings.desktop.columns,
    gridSettings.mobile.gap,
    gridSettings.mobile.columns,
  ]);

  /** Определяет индекс мини-карточки ТС, после которой рисовать CarRequestCard для десктопа */
  const carRequestCardIdxRender = useMemo(() => {
    if (!showRequestCard) {
      return -1;
    }

    // если карточки хотя бы в 3 колонки, то после карточки с индексом 10
    if (cars.length > 11) {
      return 10;
    }
    // если карточки хотя бы в 2 колонки, то после карточки с индексом 6
    if (cars.length > 7 && cars.length < 11) {
      return 6;
    }

    // после последней карточки
    return cars.length - 1;
  }, [cars, showRequestCard]);

  return (
    <Grid ref={(node: HTMLElement) => setGridNode(node)} {...gridSettings}>
      {isLoading && <CardsSceleton />}
      {Array.isArray(cars) &&
        cars.map((car, idx) => {
          return (
            <Fragment key={car.id}>
              <Card
                car={car}
                width={columnWidth}
                size={gridSettings.mobile.columns > 1 ? 'small' : 'medium'}
                ref={(node) => carRequestCardIdxRender === idx && setBeforeRequestCardNode(node)}
                tagToShow={tagToShow}
                showSelfTag={showSelfTag}
                isRelated={isRelated}
              />
              {carRequestCardIdxRender === idx && (
                <CarRequestCard
                  key={`car-request-${car.id}`}
                  beforeRequestCardNode={beforeRequestCardNode}
                />
              )}
            </Fragment>
          );
        })}
    </Grid>
  );
};
