import { useLayoutEffect, useState } from 'react';

import { scrollToCallbackForm } from 'utils';

import { cardContentWrapperClassName } from './Card/Card';
import {
  ContentWrapper,
  Description,
  StyledButton,
  Title,
  Wrapper,
} from './CarRequestCard.styles.';

const defaultContentWrapperHeight = 160;

export const CarRequestCard = ({
  beforeRequestCardNode,
}: {
  beforeRequestCardNode: HTMLElement | null;
}) => {
  /** необходимо определить высоту соседней карточки для одноколоночного макета */
  const [cardHeight, setCardHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(defaultContentWrapperHeight);

  useLayoutEffect(() => {
    if (!beforeRequestCardNode) {
      return;
    }

    const callback = () => {
      setCardHeight(beforeRequestCardNode.clientHeight);
      setContentHeight(
        beforeRequestCardNode.querySelector('.' + cardContentWrapperClassName)?.clientHeight ||
          defaultContentWrapperHeight,
      );
    };

    const observer = new ResizeObserver(callback);
    observer.observe(beforeRequestCardNode);

    return () => observer.disconnect();
  }, [beforeRequestCardNode]);

  return (
    <Wrapper height={cardHeight + 'px'}>
      <ContentWrapper>
        <Title>Авто под заказ</Title>
        <Description>
          Не нашли подходящее авто? Мы подберем для вас необходимую марку и модель
        </Description>
        <StyledButton color='secondary' variant='outlined' onClick={scrollToCallbackForm}>
          Оставить заявку
        </StyledButton>
      </ContentWrapper>
    </Wrapper>
  );
};
