import { Accordion, Typography } from "@mui/material";

import styled from "@emotion/styled";

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    column-gap: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }
`;

export const Description = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
`;

export const ImageWrapper = styled.img`
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;


  width: 50px;
  height: 50px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 80px;
    height: 80px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 100px;
    height: 100px;
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 12px !important;
    padding: 4px 0;
    border: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.common.white};
    &::before {
      display: none;
    }
  }
`;
