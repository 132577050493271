import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

export const PriceWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    .card-small & {
      flex-direction: column;
    }
  }
`;

export const NewPrice = styled(Typography)`
  font-weight: 500;
  font-size: 30px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    .card-small & {
      font-size: 22px;
      line-height: 22px;
      letter-spacing: -0.75px;
    }
  }
`;

export const OldPrice = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbl.Grey};
  text-decoration: line-through;
`;
