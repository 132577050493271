import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Slider } from 'components/Slider/Slider';

const lineClamp = (lines: number) => css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Wrapper = styled(Link)`
  position: relative;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

export const StyledSlider = styled(Slider)`
  & .slick-dots {
    bottom: 10px;
  }

  && {
    .slick-dots li:not(.slick-active) button::before {
      color: ${({ theme }) => theme.palette.vtbAuto.HawaiianSky};
    }

    .slick-dots li.slick-active button::before {
      color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
    }
  }
`;

export const ImageWrapper = styled(Box)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
`;

export const SlideImage = styled('img')`
  max-height: 100%;
  margin: auto;
  max-width: 100%;
`;

export const ContentWrapper = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 4px;

  & > * {
    width: 100%;
  }
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.common.black};
  ${lineClamp(2)}

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    .card-small & {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }
`;

export const Complectation = styled(Typography)`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
  ${lineClamp(1)}

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    .card-small & {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }
`;

export const Options = styled(Typography)`
  margin-top: 4px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    .card-small & {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 16px;
    letter-spacing: -0.5px;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-top: auto;

  && {
    background: ${({ theme }) => theme.palette.vtbl.WhiteSmoke};
    color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};

    &:hover {
      background: ${({ theme }) => theme.palette.vtbAuto.PrimaryBlueGradient};
      color: ${({ theme }) => theme.palette.common.white};
    }

    &:focus {
      background: ${({ theme }) => theme.palette.vtbAuto.DarkBlueGradient};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;




export const StyledTagsStack = styled(Stack)`
  column-gap: 4px;
  position: absolute;
  top: 16px;
  left: 16px;
`;
