import { FC } from "react";
import { Box } from "@mui/material";

import { NotFound } from "components";

export const NoItems: FC = () => {
  return (
    <Box my={{ xs: "32px", lg: "64px" }}>
      <NotFound
        title="По вашему запросу ничего не нашлось"
        description="Попробуйте повторить поиск."
      />
    </Box>
  );
};
