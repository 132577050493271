import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

import { useBreakpoints } from 'hooks';

export const RelatedCarsSkeleton: FC = () => {
  const { isDesktop } = useBreakpoints();

  return (
    <Stack
      sx={{ rowGap: { xs: '24px', lg: '32px' }, mt: { xs: '24px', lg: '32px' } }}
      width={'100%'}
    >
      <Skeleton variant='rounded' height={'27px'} width={'25%'} />
      <Stack flexDirection={'row'} sx={{ columnGap: { xs: '18px', lg: '32px' } }}>
        {Array(isDesktop ? 4 : 2)
          .fill(null)
          .map((_, idx) => (
            <Skeleton
              key={idx}
              variant='rounded'
              sx={{
                height: { xs: '310px', sm: '445px', lg: '506px' },
                width: { xs: '50%', lg: '25%' },
              }}
            />
          ))}
      </Stack>
    </Stack>
  );
};
