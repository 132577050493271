import {Button, ButtonGroup, styled, Typography} from "@mui/material";

export const SubscriptionButtonGroup = styled(ButtonGroup)`
  width: 100%;
  height: 40px;
  margin-bottom: 4px;

  ${({theme}) => theme.breakpoints.up('lg')} {
    height: 60px;
  }
`;

export const SubscriptionPeriodButton = styled(Button)`
  flex-grow: 0;
  flex-basis: 100%;
  border-radius: 4px;

  &&.MuiButton-text {
    background: #e9eff3;
    color: ${({theme}) => theme.palette.vtbAuto.PrimaryGray};

    & + & {
      border-left: 1px solid #cfd7dc;
    }
  }
`;

export const Run = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 20px;
    line-height: 24px;
  }
`;


