import type { ThemeOptions } from "@mui/material";
import { alpha, css } from "@mui/material";

export const buttonOptions: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: () => css`
          font-weight: 400;
          box-shadow: none;
        `,
        containedPrimary: ({ theme }) => css`
          && {
            background: ${theme.palette.vtbAuto.PrimaryBlueGradient};

            &:hover {
              background: ${theme.palette.vtbAuto.DarkBlueGradient};
            }

            &:focus {
              background: linear-gradient(
                ${theme.palette.vtbAuto.BluePrimary},
                ${theme.palette.vtbl.DeepBlue}
              );
            }

            &:disabled {
              background: linear-gradient(
                  ${alpha(theme.palette.common.white, 0.44)},
                  ${alpha(theme.palette.common.white, 0.44)}
                ),
                linear-gradient(
                  ${theme.palette.vtbAuto.BluePrimary},
                  ${theme.palette.vtbl.DeepBlue}
                );
              color: ${theme.palette.vtbl.ProcessCyan};
            }
          }
        `,
        containedSecondary: ({ theme }) => css`
          && {
            background: ${theme.palette.common.white};
            color: ${theme.palette.vtbAuto.BlueAccent};
            fill: ${theme.palette.vtbAuto.BlueAccent};

            &:hover {
              background: ${theme.palette.common.white};
              color: ${theme.palette.vtbAuto.BluePrimary};
              fill: ${theme.palette.vtbAuto.BluePrimary};
            }

            &:focus {
              background: ${theme.palette.common.white};
              color: ${theme.palette.vtbl.DeepBlue};
              fill: ${theme.palette.vtbl.DeepBlue};
            }

            &:disabled {
              background: ${theme.palette.common.white};
              color: ${theme.palette.vtbl.LightSkyBlue};
              fill: ${theme.palette.vtbl.LightSkyBlue};
            }
          }
        `,
        outlined: () => css`
          && {
            border-width: 1px;

            &:hover,
            &:focus,
            &:disabled {
              border-width: 1px;
            }
          }
        `,
        outlinedPrimary: ({ theme }) => css`
          && {
            border-color: ${theme.palette.vtbAuto.BluePrimary};
            color: ${theme.palette.vtbAuto.BluePrimary};
            fill: ${theme.palette.vtbAuto.BluePrimary};

            &:hover,
            &:focus {
              border-color: ${theme.palette.vtbAuto.BluePrimary};
              background: ${theme.palette.vtbAuto.BluePrimary};
              color: ${theme.palette.common.white};
              fill: ${theme.palette.common.white};
            }

            &:disabled {
              border-color: ${theme.palette.vtbl.Silver};
              color: ${theme.palette.vtbl.Silver};
              fill: ${theme.palette.vtbl.Silver};
            }
          }
        `,
        outlinedSecondary: ({ theme }) => css`
          && {
            border-color: ${theme.palette.common.white};
            color: ${theme.palette.common.white};
            fill: ${theme.palette.common.white};

            &:hover,
            &:focus {
              border-color: ${theme.palette.common.white};
              background: ${alpha(theme.palette.common.white, 0.1)};
              color: ${theme.palette.common.white};
              fill: ${theme.palette.common.white};
            }

            &:disabled {
              border-color: ${theme.palette.vtbl.LightGrey};
              color: ${theme.palette.vtbl.LightGrey};
              fill: ${theme.palette.vtbl.LightGrey};
            }
          }
        `,
        sizeMedium: () => css`
          && {
            padding: 12px 16px;
          }
        `,
        sizeLarge: () => css`
          && {
            padding: 20px 16px;
          }
        `,
      },
    },
  },
};
