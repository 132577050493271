import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const TagsContainer = styled(Stack)`
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;

  ${({theme}) => theme.breakpoints.up("sm")} {
    left: 24px;
  }

  ${({theme}) => theme.breakpoints.up("lg")} {
    left: 16px;
  }
`;
