import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { Autocomplete, Chip } from '@mui/material';
import TextField from '@mui/material/TextField';

import type { RequireOne } from '~/types/utils';

export const AutoComplete = <T,>({
  TextFieldProps = {},
  ...props
}: {
  TextFieldProps?: TextFieldProps;
} & RequireOne<AutocompleteProps<T, true, boolean, boolean>, 'options'>) => {
  return (
    <Autocomplete
      multiple
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          sx={{
            '.MuiInputBase-root': { background: (theme) => theme.palette.common.white },
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                color='info'
                size='small'
                {...getTagProps({ index })}
                key={index}
                label={props.getOptionLabel?.(option) ?? (option as string)}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      {...props}
    />
  );
};
