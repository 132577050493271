import { css } from "@emotion/react";
import { ThemeOptions } from "@mui/material";

export const tooltipOptions: ThemeOptions = {
  components: {
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 100000,
        placement: "top",
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => css`
          background-color: #6b757a;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0px;
          color: ${theme.palette.common.white};
        `,
        arrow: ({ theme }) => css`
          color: #6b757a;
        `,
      },
    },
  },
};
