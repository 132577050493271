import { Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const CallbackFormWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.vtbAuto.PrimaryBlueGradient};
  border-radius: 10px;
  padding: 24px 16px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 88px 110px;
    margin: 32px 0;
  }

  && .MuiTypography-root,
  && .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.palette.common.white};


  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
`;

export const Description = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.common.white};
`;
