import { Skeleton } from '@mui/material';

import { PER_PAGE_NUMBER } from '~/services/constants';

export const TagsSceleton = () => {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, idx) => (
          <Skeleton
            key={idx}
            sx={{ borderRadius: '4px' }}
            variant='rounded'
            width={126}
            height={32}
          />
        ))}
    </>
  );
};

export const CardsSceleton = () => {
  return (
    <>
      {Array(PER_PAGE_NUMBER + 1)
        .fill(null)
        .map((_, idx) => (
          <Skeleton key={idx} variant='rounded' sx={{ height: { xs: '360px', lg: '476px' } }} />
        ))}
    </>
  );
};
