import { FC, useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import SuccessImg from "assets/images/steering-wheel.webp";
import { useBreakpoints } from "hooks";
import { scrollToCallbackForm } from "utils";

import { Title } from "../../CallbackForm.styles";

export const Success: FC = () => {
  const { isMobile } = useBreakpoints();

  useEffect(() => scrollToCallbackForm(), []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      rowGap={{ xs: "8px", lg: "20px" }}
      textAlign={{ xs: "center", lg: "start" }}
    >
      <Title>Благодарим вас за заявку!</Title>
      <Typography variant="mainTextDes">
        Свяжемся с вами в ближайшее время
      </Typography>

      <img
        width={isMobile ? "196px" : "295px"}
        height={isMobile ? "169px" : "254px"}
        src={SuccessImg}
      />
    </Stack>
  );
};
