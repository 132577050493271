import { FC } from 'react';

import { Button, Stack } from '@mui/material';

import { ReactComponent as PdfIcon } from 'assets/icons/pdf-outline.svg';
import { useBreakpoints, useIsSubscription } from 'hooks';

import { Title } from './Documents.styles';

export const Documents: FC = () => {
  const { isMobile } = useBreakpoints();
  const isSubscription = useIsSubscription();
  const docPrefix = isSubscription ? 'subscription' : 'purchase';

  return (
    <section>
      <Title>
        {isSubscription
          ? 'Документы для оформления подписки'
          : 'Документы для оформления покупки с оплатой частями'}
      </Title>

      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'stretch'}
        columnGap='16px'
        rowGap='16px'
      >
        <Button
          variant='outlined'
          href={`/assets/files/${docPrefix}-rental-agreement.pdf`}
          target='_blank'
          rel='noopener noreferrer'
          startIcon={<PdfIcon />}
          sx={{
            justifyContent: isMobile ? 'start' : 'center',
            flex: '1 0 0',
          }}
        >
          Договор аренды
        </Button>
        <Button
          variant='outlined'
          href={`/assets/files/${docPrefix}-rental-rules.pdf`}
          target='_blank'
          rel='noopener noreferrer'
          startIcon={<PdfIcon />}
          sx={{
            justifyContent: isMobile ? 'start' : 'center',
            flex: '1 0 0',
          }}
        >
          Правила аренды
        </Button>
        <Button
          variant='outlined'
          href={`/assets/files/${docPrefix}-sski.pdf`}
          target='_blank'
          rel='noopener noreferrer'
          startIcon={<PdfIcon />}
          sx={{
            justifyContent: isMobile ? 'start' : 'center',
            flex: '1 0 0',
          }}
        >
          Согласие субъекта кредитной истории
        </Button>
      </Stack>
    </section>
  );
};
