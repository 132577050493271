import { FC } from 'react';

import { TCarTagColor } from 'types';

import { Tag } from './CarTag.styles';

type Props = {
  label: string;
  color: TCarTagColor;
};

export const CarTag: FC<Props> = ({ label, color }) => {
  return <Tag label={label} colorToken={color} />;
};
