import { CarTag } from '~/components';
import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';

import { TagsContainer } from './SliderTags.styles';

export const SliderTags = ({ car }: { car: TCar }) => {
  const isSubscription = useIsSubscription();
  const tag = car.newTags[0];

  return (
    tag && (
      <TagsContainer>
        {isSubscription ? (
          <CarTag label='В подписку' color='blue' />
        ) : (
          <CarTag label={tag.label} color={tag.color} />
        )}
        {car.count === 0 && <CarTag label='Нет в наличии' color='black' />}
        {car.special_offer && <CarTag label='🔥' color='white' />}
      </TagsContainer>
    )
  );
};
