import { Box, Button, Typography } from '@mui/material';

import styled from '@emotion/styled';

import CarRequestImage from './CarRequest.png';

export const Wrapper = styled(Box)`
  display: flex;
  border-radius: 8px;
  background:
    url(${CarRequestImage}) no-repeat top / contain,
    #96aaff;
  padding: 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 20px;
  }
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: auto;
`;

export const Title = styled(Typography)`
  font-size: 28px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-weight: 500;
    font-size: 30px;
  }
`;

export const Description = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    letter-spacing: 0;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
`;
