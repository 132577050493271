import type { FC } from 'react';

import { Global } from '@emotion/react';
import { getDisplayName } from '@vtbl/utils';

import { fontFace } from './fonts.styles';

export const VtbAutoFonts = () => {
  return <Global styles={fontFace} />;
};

export const withVtbAutoFonts = <T,>(Child: FC<T>) => {
  const WithVtbAutoFonts = (props: T) => (
    <>
      <VtbAutoFonts />
      {/* @ts-ignore */}
      <Child {...props} />
    </>
  );

  WithVtbAutoFonts.displayName = `WithVtbAutoFonts(${getDisplayName(Child)})`;

  return WithVtbAutoFonts;
};
