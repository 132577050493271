import { useMemo } from 'react';

import { Theme, useMediaQuery } from '@mui/material';

/** 
 * Shares media flags
 * */
export function useBreakpoints() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
    }),
    [isMobile, isTablet, isDesktop],
  );
}
