import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { NotFound } from "components";

import { Wrapper } from "./NotFoundPage.styles";

export const NotFoundPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <NotFound
        title="Похоже, такой страницы не существует"
        buttonText="Перейти в каталог"
        onButtonClick={() => navigate("/")}
      />
    </Wrapper>
  );
};
