import type { FC } from 'react';

import { getDisplayName } from '@vtbl/utils';
import { SWRConfig } from 'swr';

const swrConfig = { shouldRetryOnError: false, revalidateOnFocus: false };

export const withSWRConfig = <T,>(Child: FC<T>) => {
  const WithSWRConfig = (props: T) => {
    return (
      <SWRConfig value={swrConfig}>
        {/* @ts-ignore */}
        <Child {...props} />
      </SWRConfig>
    );
  };

  WithSWRConfig.displayName = `WithSWRConfig(${getDisplayName(Child)})`;

  return WithSWRConfig;
};
