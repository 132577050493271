import type { FC } from 'react';

import { Card } from '~/modules';
import Catalog from '~/modules/Catalog/Catalog';

import { NotFoundPage } from './pages';

export type NavigationEventName =
  | 'NotFoundPage'
  | 'SubscriptionCard'
  | 'PurchaseCard'
  | 'SubscriptionCatalogue'
  | 'PurchaseCatalogue';

// TODO LazyLoading
export const routeConfig: Record<
  NavigationEventName,
  { Component: FC; path: string; name: NavigationEventName }
> = {
  NotFoundPage: { path: '404', Component: NotFoundPage, name: 'NotFoundPage' },
  SubscriptionCatalogue: { path: 'podpiska', Component: Catalog, name: 'SubscriptionCatalogue' },
  PurchaseCatalogue: { path: 'pokupka', Component: Catalog, name: 'PurchaseCatalogue' },
  PurchaseCard: { path: 'pokupka/:id', Component: Card, name: 'PurchaseCard' },
  SubscriptionCard: { path: 'podpiska/:id', Component: Card, name: 'SubscriptionCard' },
};