import type { PropsWithChildren } from 'react';

import { ThemeProvider } from '@mui/material';

import { VtblThemeProvider } from '@vtbl/mui-kit';

import { mergeNestedTheme } from './theme';

export const VtbAutoThemeProvider = ({ children }: PropsWithChildren) => {
  return (
    <VtblThemeProvider>
      <ThemeProvider theme={mergeNestedTheme}>{children}</ThemeProvider>
    </VtblThemeProvider>
  );
};
