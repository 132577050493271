import { type ThemeOptions } from '@mui/material';

import { css } from '@emotion/react';

export const menuItem: ThemeOptions = {
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => css`
          color: ${theme.palette.vtbAuto.PrimaryGray};
          line-height: 18px;
          letter-spacing: -0.5px;
          padding: 14px 16px;
        `,
      },
    },
  },
};
