import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)`
  margin: 128px 0;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 32px 0;
  }
`;
