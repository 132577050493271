import { Accordion } from '@mui/material';

import styled from '@emotion/styled';

export const AccordionStyled = styled(Accordion)`
  background: transparent;
  color: ${({theme}) => theme.palette.vtbAuto.BlueDark};

  &:not(:first-of-type)::before {
    display: block!important;
    opacity: 1 !important;
  }
`;