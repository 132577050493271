import { FC } from "react";
import { Box, Button, Stack } from "@mui/material";

import { useBreakpoints, useIsSubscription } from "hooks";
import { scrollToCallbackForm } from "utils";

import {
  BannerContainer,
  Description,
  LinkTitle,
  StyledContainer,
  StyledLink,
  Title,
} from "./Banner.styles";

export const Banner: FC = () => {
  const { isMobile } = useBreakpoints();
  const isSubscription = useIsSubscription();

  return (
    <BannerContainer
      isSubscription={isSubscription}
      isPurchase={!isSubscription}
      role="img"
    >
      <StyledContainer>
        {isMobile && (
          <Stack
            height="100%"
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Stack direction="column" alignItems="stretch" spacing="32px">
              <Stack direction="row" alignItems="stretch">
                <StyledLink to="/pokupka?page=1" left>
                  <LinkTitle>Покупка</LinkTitle>
                </StyledLink>
                <StyledLink to="/podpiska?page=1" right>
                  <LinkTitle>Подписка</LinkTitle>
                </StyledLink>
              </Stack>

              <Title textAlign="center">
                {isSubscription ? (
                  <>
                    Современный способ владеть <br /> автомобилем, не покупая
                    его
                  </>
                ) : (
                  <>
                    Разные варианты покупки <br /> в наличии и под заказ
                  </>
                )}
              </Title>
            </Stack>

            <Button
              variant="contained"
              color="secondary"
              onClick={scrollToCallbackForm}
            >
              Оставить заявку
            </Button>
          </Stack>
        )}

        {!isMobile && (
          <Stack
            height="100%"
            direction="column"
            justifyContent="space-between"
          >
            <Box>
              <Title>
                Выбирайте свой способ <br /> владеть автомобилем
              </Title>
              <Description variant="body1">
                Онлайн-сервис с собственным парком <br /> и низкими ценами
              </Description>
            </Box>

            <Stack direction="row" alignItems="stretch" spacing="32px">
              <StyledLink to="/pokupka?page=1">
                <LinkTitle>Покупка</LinkTitle>
                <Description>
                  Разные варианты покупки авто в наличии и под заказ
                </Description>
              </StyledLink>

              <StyledLink to="/podpiska?page=1">
                <LinkTitle>Подписка</LinkTitle>
                <Description>
                  Современный способ владеть автомобилем, не покупая его
                </Description>
              </StyledLink>
            </Stack>
          </Stack>
        )}
      </StyledContainer>
    </BannerContainer>
  );
};
