import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: -0.8px;
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 18px;
  }
`;
