import { FC, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useBreakpoints, useIsSubscription } from 'hooks';
import type { CardsProps } from 'modules/Catalog/Cards/Cards';
import { Cards } from 'modules/Catalog/Cards/Cards';
import { useRelatedCars } from 'queries';

import { RelatedCarsSkeleton } from './RelatedCars.skeleton';
import { LoadMoreButton, Title, Wrapper } from './RelatedCars.styles';

const getItemsPerRow = (isDesktop: boolean) => (isDesktop ? 4 : 2);

type Props = {
  id?: string;
} & Pick<CardsProps, 'showSelfTag'>;
export const RelatedCars: FC<Props> = ({ id, showSelfTag }) => {
  const isSubscription = useIsSubscription();

  const { data: relatedCars, isValidating } = useRelatedCars({
    carId: id,
    carType: isSubscription ? 'subscription' : 'purchase',
  });
  const { isDesktop } = useBreakpoints();
  const { pathname } = useLocation();

  const [renderCount, setRenderCount] = useState(getItemsPerRow(isDesktop));

  const loadMore = () => {
    setRenderCount((prevCount) => prevCount + getItemsPerRow(isDesktop));
  };

  useLayoutEffect(() => {
    // Сброс количества карточек до значения по-умолчанию
    setRenderCount(getItemsPerRow(isDesktop));
  }, [isDesktop, pathname]);

  if (isValidating) {
    return <RelatedCarsSkeleton />;
  }

  if (!relatedCars?.length) {
    return null;
  }

  return (
    <section>
      {relatedCars && (
        <Wrapper>
          <Title>Вам может быть интересно</Title>
          <Cards
            cars={relatedCars?.slice(0, renderCount)}
            grid={{ mobile: { columns: 2, gap: 16 } }}
            showSelfTag={showSelfTag}
            isRelated
          />
          {relatedCars.length > renderCount && (
            <LoadMoreButton variant='contained' onClick={loadMore}>
              Загрузить еще
            </LoadMoreButton>
          )}
        </Wrapper>
      )}
    </section>
  );
};
