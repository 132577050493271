import { Box } from '@mui/material';

import styled from '@emotion/styled';

export const Grid = styled(Box)<{
  mobile: { gap: number; columns: number };
  tablet: { gap: number; columns: number };
  desktop: { gap: number; columns: number };
}>`
  display: grid;
  grid-template-rows: 1fr;
  gap: 16px ${({ mobile }) => mobile.gap}px;
  grid-template-columns: repeat(${({ mobile }) => mobile.columns}, 1fr);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(${({ tablet }) => tablet.columns}, 1fr);
    column-gap: ${({ tablet }) => tablet.gap}px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(${({ desktop }) => desktop.columns}, 1fr);
    column-gap: ${({ desktop }) => desktop.gap}px;
  }

  &:not(:empty) {
    margin-top: 26px;

    ${({ theme }) => theme.breakpoints.up('lg')} {
      margin-top: 32px;
    }
  }
`;
