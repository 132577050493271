import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as CarFront } from 'assets/icons/car-front.svg';
import { ReactComponent as CarSide } from 'assets/icons/car-side.svg';
import { ReactComponent as Engine } from 'assets/icons/engine.svg';
import { ReactComponent as Gearbox } from 'assets/icons/gearbox.svg';
import { ReactComponent as Horse } from 'assets/icons/horse.svg';
import { ReactComponent as Leaf } from 'assets/icons/leaf.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Owner } from 'assets/icons/owner.svg';
import { ReactComponent as Transmission } from 'assets/icons/transmission.svg';

import { TProperties } from './CarProperties.types';

export const propertiesOrder: (keyof TProperties)[] = [
  'complectation_name',
  'engine_eco',
  'engine',
  'registry_year',
  'power_hp',
  'body_type',
  'drive',
  'gearbox',
];

export const usedCarPropertiesOrder: (keyof TProperties)[] = [
  'registry_year',
  'mileage',
  'owners_number',
  'engine',
  'complectation_name',
  'drive',
  'gearbox',
  'body_type',
  'power_hp',
  'engine_eco',
];

export const propertiesConfig: Record<
  keyof TProperties,
  {
    title: string;
    Icon: any;
  }
> = {
  registry_year: {
    title: 'Год выпуска',
    Icon: Calendar,
  },
  mileage: {
    title: 'Пробег',
    Icon: CarFront,
  },
  owners_number: {
    title: 'Владельцы',
    Icon: Owner,
  },
  engine: {
    title: 'Двигатель',
    Icon: Engine,
  },
  complectation_name: {
    title: 'Комплектация',
    Icon: List,
  },
  drive: {
    title: 'Привод',
    Icon: Transmission,
  },
  gearbox: {
    title: 'КПП',
    Icon: Gearbox,
  },
  body_type: {
    title: 'Кузов',
    Icon: CarSide,
  },
  power_hp: {
    title: 'Мощность',
    Icon: Horse,
  },
  engine_eco: {
    title: 'Экокласс',
    Icon: Leaf,
  },
};
