import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

import { useBreakpoints } from '~/hooks';

import {
  BOTTOM_SLIDER_IMAGE_HEIGHT,
  BOTTOM_SLIDER_IMAGE_WIDTH,
  TOP_SLIDER_HEIGHT_BREAKPOINTS,
} from './CarSlider.styles';

export const CarSliderSkeleton: FC = () => {
  const { isDesktop } = useBreakpoints();

  return (
    <Stack rowGap='8px'>
      <Skeleton
        variant='rounded'
        sx={{
          height: TOP_SLIDER_HEIGHT_BREAKPOINTS,
        }}
      />

      {isDesktop && (
        <Stack flexDirection='row' columnGap='10px'>
          {[...new Array(6)].map((_, idx) => (
            <Skeleton
              key={idx}
              variant='rounded'
              width={BOTTOM_SLIDER_IMAGE_WIDTH}
              height={BOTTOM_SLIDER_IMAGE_HEIGHT}
            />
          ))}
          <Skeleton
            variant='rounded'
            width={`calc(${BOTTOM_SLIDER_IMAGE_WIDTH} / 2)`}
            height={BOTTOM_SLIDER_IMAGE_HEIGHT}
          />
        </Stack>
      )}
    </Stack>
  );
};
