import type { ThemeOptions } from '@mui/material';

import { theme } from '@vtbl/mui-kit';

export const typographyOptions: ThemeOptions = {
  typography: () => {
    // перебиваем fontFamily в теме ЦАЛ
    const res = {
      ...Object.entries(theme.typography).reduce(
        (acc, [key, value]) => {
          if (typeof value === 'object' && 'fontFamily' in value) {
            return { ...acc, [key]: { ...value, fontFamily: acc.fontFamily } };
          } else if (key === 'fontFamily') {
            return acc;
          } else {
            return { ...acc, [key]: value };
          }
        },
        {
          fontFamily: "'VTB Group UI', sans-serif",
        },
      ),
      h3: {
        fontWeight: '400',
        fontSize: '30px',
        letterSpacing: '-0.5px',
      },
      button: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '-0.5px',
        textTransform: 'none' as const,
      },
      mainTextDes: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '1.1',
        letterSpacing: '-0.8px',
      },
    };
    return res;
  },
};
