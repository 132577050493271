import { FC } from 'react';

import { Stack } from '@mui/material';

import { IconContainer, Text, Title } from './PropertyItem.styles';

type Props = {
  title: string;
  text?: string;
  Icon: any;
};
export const PropertyItem: FC<Props> = ({ title, text, Icon }) => {
  return (
    <Stack flexDirection='row' columnGap='8px'>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Stack>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Stack>
    </Stack>
  );
};
