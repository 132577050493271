import { emailPattern, maxLength, onlyRussianPattern, required, validateMinLength } from './rules';

export const nameRules = {
  required,
  maxLength: maxLength(120),
  pattern: onlyRussianPattern,
  validate: { validateMinLength: validateMinLength(2) },
};

export const emailRules = {
  pattern: emailPattern,
  validateMinLength,
};
