import { Button, Container, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import styled from "@emotion/styled";

import { Logo } from "../Logo";

export const StyledFooter = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: auto;
`;

export const StyledContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

export const FooterLogo = styled(Logo)`
  display: none;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: block;
    margin-bottom: 45px;
  }
`;

export const FooterTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbl.Grey};
`;

export const FooterTypographyInfo = styled(FooterTypography)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const FooterButton = styled(Button)`
  &&.MuiButton-root {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    padding: 9px 20px;
  }

  &&.MuiButton-contained {
    background: ${({ theme }) => theme.palette.vtbAuto.AliceBlue};
    color: ${({ theme }) => theme.palette.vtbAuto.BluePrimary};
    border-color: ${({ theme }) => theme.palette.vtbAuto.AliceBlue};
  }

  &&.MuiButton-contained:hover,
  &&.MuiButton-contained:focus {
    background: ${({ theme }) => theme.palette.vtbAuto.AliceBlue};
    color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
    border-color: ${({ theme }) => theme.palette.vtbAuto.AliceBlue};
  }
`;

export const MailLink = styled(Link)`
  display: block;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  text-decoration: none;
  margin-top: 24px;
`;
