import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";

export const StyledHeader = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const MenuWrapper = styled(Box)`
  display: flex;
  column-gap: 32px;
  align-items: center;
`;
