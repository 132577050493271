import { FC } from 'react';

import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';
import { separateThousands } from '~/utils';

import { NewPrice, OldPrice, PriceWrapper } from './Price.styles';

type Props = {
  car: TCar;
};
export const Price: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  if (isSubscription) {
    return (
      <PriceWrapper>
        {car.commonPrice > 0 && (
          <NewPrice>{`от ${separateThousands(car.commonPrice)} ₽ / мес.`}</NewPrice>
        )}
        {car.specialPrice > 0 && car.specialPrice != car.commonPrice && (
          <OldPrice>{`от ${separateThousands(car.specialPrice)} ₽ / мес.`}</OldPrice>
        )}
      </PriceWrapper>
    );
  } else {
    const newPrice = car.pokupkaPrice != car.fullPrice ? car.pokupkaPrice : car.fullPrice;
    const oldPrice = car.pokupkaPrice != car.fullPrice && car.fullPrice;

    return (
      <PriceWrapper>
        {newPrice && <NewPrice>{`${separateThousands(newPrice)} ₽`}</NewPrice>}
        {oldPrice && <OldPrice>{`${separateThousands(oldPrice)} ₽`}</OldPrice>}
      </PriceWrapper>
    );
  }
};
