import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { CallbackForm, Documents, Faq, Seo } from 'components';
import { useBreakpoints, useIsSubscription } from 'hooks';
import { useCar } from 'queries';
import { ECarTagLabel } from 'types';

import { routeConfig } from '~/app/routeConfig';

import { CarDetails, CarProperties, GoToCatalogueLInk, RelatedCars } from './components';

export const Card: FC = () => {
  const { id } = useParams();
  const { isDesktop } = useBreakpoints();
  const { data: car, error } = useCar(id);
  const navigate = useNavigate();
  const isSubscription = useIsSubscription();

  if (
    (car && (isSubscription ? !car.for_subscription : !car.for_sale)) ||
    error?.response.status === 404
  ) {
    navigate('/' + routeConfig.NotFoundPage.path);
  }

  const title = isSubscription ? (
    <>
      Оставьте заявку
      {isDesktop ? <br /> : ''} на авто по подписке
    </>
  ) : (
    <>
      От вас – заявка,
      <br />
      от нас – выгодные условия!
    </>
  );

  return (
    <Stack
      direction='column'
      rowGap={isDesktop ? '32px' : '24px'}
      marginBottom={isDesktop ? '32px' : '24px'}
    >
      <Seo car={car} />
      <GoToCatalogueLInk />
      <CarDetails car={car} />
      <CarProperties car={car} />
      <CallbackForm car={car} title={title} />
      <RelatedCars id={id} showSelfTag={!isSubscription} />
      {/* Отображаем документы и FAQ всегда для подписки и для покупки с тегом "Оплата частями" */}
      {isSubscription ||
        (car?.newTags.some((tag) => tag.label === ECarTagLabel.PartPayments) && (
          <>
            <Documents />
            <Faq />
          </>
        ))}
    </Stack>
  );
};
