import React, { FC, Fragment, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';

import { useIsSubscription } from 'hooks';
import { TCar } from 'types';
import { separateThousands } from 'utils';

import { propertiesConfig, propertiesOrder, usedCarPropertiesOrder } from './CarProperties.config';
import { CarPropertiesSkeleton } from './CarProperties.skeleton';
import { AccordionStyled } from './CarProperties.styles';
import { TProperties } from './CarProperties.types';
import { PropertyItem } from './components';

type Props = {
  car?: TCar;
};
export const CarProperties: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  const isUsed = !isSubscription && (car?.owners_number || 0) > 0;

  const properties: TProperties | undefined = useMemo(() => {
    if (!car) {
      return;
    }

    const data: TProperties = {
      complectation_name: car.complectation_name,
      engine_eco: `Евро ${car.engine_eco}`,
      engine: `${car.engine_type} / ${car.engine} л.`,
      registry_year: `${car.registry_year}${car.owners_number ? '' : ' / Новый'}`,
      power_hp: `${car.power_hp} л.с.`,
      body_type: car.body_type,
      drive: car.drive,
      gearbox: car.gearbox,
    };

    if (isUsed) {
      data.mileage = car.mileage ? `${separateThousands(car.mileage)} км` : '';
      data.owners_number = car.owners_number ? `${car.owners_number} по ПТС` : '';
    }

    return data;
  }, [car, isUsed]);

  if (!car || !properties) {
    return <CarPropertiesSkeleton />;
  }

  return (
    <Stack rowGap={{ xs: '24px', lg: '32px' }}>
      <Grid container columns={isUsed ? 10 : 12} spacing={{ xs: '16px' }}>
        {(isUsed ? usedCarPropertiesOrder : propertiesOrder).map((key) => {
          const text = properties[key];
          const { title, Icon } = propertiesConfig[key];

          if (!text) {
            return null;
          }
          return (
            <Grid item xs={isUsed ? 5 : 6} md={isUsed ? 2 : 3}>
              <PropertyItem key={key} title={title} text={text} Icon={Icon} />
            </Grid>
          );
        })}
      </Grid>

      <Box>
        {Boolean(car.accordion?.length) &&
          car.accordion!.map(({ title, content }) => {
            return (
              <AccordionStyled elevation={0} square disableGutters key={title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {[content].flat().map((item, idx) => {
                    if (item.type === 'paragraph') {
                      return (
                        <Typography key={idx} py='2px'>
                          • {item.text.slice(1, item.text.length)}
                        </Typography>
                      );
                    } else {
                      return (
                        <Fragment key={idx}>
                          {item.items.map((item, idx) => (
                            <Typography key={idx}>• {item}</Typography>
                          ))}
                        </Fragment>
                      );
                    }
                  })}
                </AccordionDetails>
              </AccordionStyled>
            );
          })}
      </Box>
    </Stack>
  );
};
