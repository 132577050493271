import { FC } from "react";
import { Button, Stack } from "@mui/material";

import SadCar from "assets/images/sad-car.webp";
import { useBreakpoints } from "hooks";

import { Description, Title } from "./NotFound.styles";
import { Image } from "@mui/icons-material";

type Props = {
  title: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: () => void;
};
export const NotFound: FC<Props> = ({
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  const { isMobile } = useBreakpoints();
  const imageSize = isMobile ? "150px" : "300px";

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      rowGap={{ xs: "24px", lg: "32px" }}
    >
      <Stack flexDirection="column" alignItems="center" rowGap="16px">
        <img
          width={imageSize}
          height={imageSize}
          src={SadCar}
          alt="Нет изображения"
        />
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Stack>

      {buttonText && (
        <Button
          variant="outlined"
          size="large"
          onClick={onButtonClick}
          fullWidth={isMobile}
          sx={{
            padding: isMobile ? undefined : "21px 100px!important",
          }}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};
