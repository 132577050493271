import { useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { SESSION_STORAGE_SUBSCRIPTION_TERM_KEY } from '~/consts';
import { TCar } from '~/types';
import { getCarOptions, separateThousands } from '~/utils';

import { OldPrice, Options, Price } from '../../CarInfo.styles';
import { CarDescription } from '../CarDescription';
import { Run, SubscriptionButtonGroup, SubscriptionPeriodButton } from './SubscriptionInfo.styles';

export const SubscriptionInfo = ({ car }: { car: TCar }) => {
  const [subscriptionPeriodIdx, setSubscriptionPeriodIdx] = useState(0);
  const { paymentMonthly, run, priceSpec } = car?.contract_type?.subscription || {};

  useEffect(() => {
    // Сохраняем условия подписки в session storage для использования в ФОС
    sessionStorage.setItem(
      SESSION_STORAGE_SUBSCRIPTION_TERM_KEY,
      JSON.stringify({
        period: paymentMonthly[subscriptionPeriodIdx].duration,
        price: paymentMonthly[subscriptionPeriodIdx].value,
      }),
    );

    // Очищаем условия подписки при удалении компонента
    return () => sessionStorage.removeItem(SESSION_STORAGE_SUBSCRIPTION_TERM_KEY);
  }, [subscriptionPeriodIdx]);

  const carOptions = useMemo(() => {
    return getCarOptions([
      car.engine_type,
      car.power_hp + ' л.с.',
      car.engine + ' л.',
      car.gearbox,
      car.drive,
      car.color,
    ]);
  }, [car]);

  return (
    <Stack>
      <Options marginBottom={{ xs: '16px', lg: '32px' }}>{carOptions}</Options>
      {Boolean(paymentMonthly?.length) && (
        <>
          <SubscriptionButtonGroup
            size='medium'
            sx={{
              marginBottom: { xs: '4px', lg: '24px' },
            }}
          >
            {paymentMonthly.map(({ duration }, idx) => (
              <SubscriptionPeriodButton
                key={idx}
                variant={subscriptionPeriodIdx === idx ? 'contained' : 'text'}
                onClick={() => setSubscriptionPeriodIdx(idx)}
              >
                {`${separateThousands(duration)} мес`}
              </SubscriptionPeriodButton>
            ))}
          </SubscriptionButtonGroup>

          <Stack rowGap='4px' marginBottom={{ xs: '16px', lg: '24px' }}>
            <Run>{`Включено ${separateThousands(run[subscriptionPeriodIdx].value)} км пробега`}</Run>
            <Price>{`от ${separateThousands(
              paymentMonthly[subscriptionPeriodIdx].value,
            )} ₽ / мес.`}</Price>
            {priceSpec[subscriptionPeriodIdx].value > 0 && (
              <OldPrice>{`от ${separateThousands(
                priceSpec[subscriptionPeriodIdx].value,
              )} ₽ / мес.`}</OldPrice>
            )}
          </Stack>

          <CarDescription
            header='Авто по подписке 🚘'
            items={[
              'Новый авто без первоначального взноса и крупных вложений',
              'Страхование, ТО, шинный сервис и налоги уже включены в подписку',
              'Консьерж-сервис с доставкой до дома',
              'Авто в наличии в парке ВТБ',
            ]}
          />
        </>
      )}
    </Stack>
  );
};
