import { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Container,
  IconButton,
  Link,
  Stack,
  useTheme,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { SUPPORT_PHONE, TELEGRAM_URL, WHATSAPP_URL } from "consts";
import { useBreakpoints } from "hooks";
import { scrollToCallbackForm } from "utils";

import { routeConfig } from "../../../routeConfig";
import { MenuWrapper, StyledContainer, StyledHeader } from "./Header.styles";
import { Logo } from "../Logo/Logo";

const menuItems = [
  { name: "Покупка", to: routeConfig.PurchaseCatalogue.path },
  { name: "Подписка", to: routeConfig.SubscriptionCatalogue.path },
];

export const Header: FC = () => {
  const { isDesktop } = useBreakpoints();
  const theme = useTheme();

  return (
    <StyledHeader component={"header"}>
      <StyledContainer>
          <Stack direction="row" spacing="71px">
            <Logo />

            {isDesktop && (
              <MenuWrapper>
                {menuItems.map(({ name, to }) => (
                  <Link
                    key={to}
                    component={NavLink}
                    // @ts-ignore
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            color: theme.palette.vtbAuto.BluePrimary,
                            cursor: "default",
                            fontWeight: 500,
                          }
                        : { color: theme.palette.vtbAuto.BlueAccent };
                    }}
                    to={to}
                    underline="none"
                    variant="body1"
                  >
                    {name}
                  </Link>
                ))}
              </MenuWrapper>
            )}
          </Stack>

          {isDesktop ? (
            <Stack direction="row" columnGap="24px">
              <Button
                variant="outlined"
                onClick={scrollToCallbackForm}
                sx={{
                  "&&": {
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    height: "42px",
                  },
                }}
              >
                Оставить заявку
              </Button>

              <Stack direction="row" columnGap="8px">
                <IconButton color="primary" href={TELEGRAM_URL}>
                  <TelegramIcon />
                </IconButton>
                <IconButton color="primary" href={WHATSAPP_URL}>
                  <WhatsAppIcon />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" columnGap="8px">
              <IconButton
                href={`tel:${SUPPORT_PHONE}`}
                sx={(theme) => ({
                  background: theme.palette.vtbAuto.PrimaryBlueGradient,
                  color: theme.palette.common.white,
                })}
              >
                <CallIcon />
              </IconButton>
              <IconButton
                href={TELEGRAM_URL}
                sx={(theme) => ({
                  background: theme.palette.vtbAuto.PrimaryBlueGradient,
                  color: theme.palette.common.white,
                })}
              >
                <TelegramIcon />
              </IconButton>
              <IconButton
                href={WHATSAPP_URL}
                sx={(theme) => ({
                  background: theme.palette.vtbAuto.PrimaryBlueGradient,
                  color: theme.palette.common.white,
                })}
              >
                <WhatsAppIcon />
              </IconButton>
            </Stack>
          )}
      </StyledContainer>
    </StyledHeader>
  );
};
