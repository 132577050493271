import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import { useSearchCars } from "queries/queries";

import {CallbackForm, Documents, Faq, Seo} from "components";
import { withSWRConfig } from "~/hocs/withSWRConfig";
import { useBreakpoints } from "~/hooks/useBreakpoints";
import { useIsSubscription } from "~/hooks/useIsSubscription";
import { PARAM_NAMES } from "~/services/constants";
import { ECarTagLabel } from "types";

import { Cards } from "./Cards/Cards";
import { Filters } from "./Filters/Filters";
import { NoItems } from "./NoItems";
import { useQueryStringParam } from "./useQueryParams";
import { Banner, PurchaseTiles, SubscriptionTiles } from "./components";
import { useLocation } from "react-router-dom";
import { SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY } from "consts";

const Catalog = () => {
  const isSubscription = useIsSubscription();
  const location = useLocation();
  const { isDesktop } = useBreakpoints();
  const { data: searchCars, isValidating, error } = useSearchCars();
  const selectedTag = useQueryStringParam(PARAM_NAMES.Tag, "");

  useEffect(() => {
    // Сохраняем параметры поиска для кнопки "В каталог"
    location.search
      ? sessionStorage.setItem(
          SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY,
          location.search
        )
      : sessionStorage.removeItem(SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY);
  }, [location.search]);

  const formProps = isSubscription
    ? {
        title: (
          <>
            Оставьте заявку
            {isDesktop ? <br /> : ""} на авто по подписке
          </>
        ),
      }
    : {
        title: "Оставьте заявку на консультацию",
        description:
          "Мы поможем определиться с выбором авто и расскажем, как его выгодно приобрести.",
      };

  const noResults =
    !isValidating && !error && searchCars && searchCars.data.length === 0;

  return (
    <Stack
      direction="column"
      rowGap={isDesktop ? "32px" : "24px"}
      marginBottom={isDesktop ? "32px" : "24px"}
    >
      <Seo />
      <Banner />
      {isSubscription ? <SubscriptionTiles /> : <PurchaseTiles />}

      <section>
        <Typography
          variant="h3"
          color={(theme) => theme.palette.vtbl.GentianBlue}
        >
          Выберите авто
        </Typography>

        <Filters>
          {noResults ? (
            <NoItems />
          ) : (
            <Cards
              cars={searchCars?.data}
              isLoading={isValidating}
              showRequestCard
              tagToShow={selectedTag}
              showSelfTag={!selectedTag}
            />
          )}
        </Filters>
      </section>

      <CallbackForm {...formProps} />

      {/* Отображаем документы и FAQ для подписок и при выборе тега "Оплата частями" */}
      {(isSubscription || selectedTag === ECarTagLabel.PartPayments) && (
        <>
          <Documents />
          <Faq />
        </>
      )}
    </Stack>
  );
};

export default withSWRConfig(Catalog);
