import { FC } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { routeConfig } from './routeConfig';

export const Routing: FC = () => {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route path='/' element={<Navigate to={routeConfig.PurchaseCatalogue.path} replace />} />

      {Object.values(routeConfig).map(({ Component, path }, index) => {
        return <Route key={index} path={path} element={<Component />} />;
      })}

      <Route path={'*'} element={<Navigate to={routeConfig.NotFoundPage.path} />} />
    </Routes>
  );
};
