import type { ControllerProps, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

export const TextFieldController = <T extends FieldValues>({
  fieldProps = {},
  ...props
}: Omit<ControllerProps<T>, 'render'> & {
  fieldProps?: TextFieldProps;
}) => {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <TextField
          size='small'
          {...field}
          {...fieldProps}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
};
