import { FC, ReactNode, useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import CarImage from 'assets/images/callback-form-car.webp';
import { useBreakpoints } from 'hooks';
import { TCar } from 'types';

import { CallbackFormWrapper, Description, Title } from './CallbackForm.styles';
import { Form, Success } from './components';

// 3 мин
const REQUEST_INTERVAL = 3 * 60 * 1000;

type Props = {
  car?: TCar;
  title: ReactNode;
  description?: ReactNode;
};
export const CallbackForm: FC<Props> = ({ car, title, description }) => {
  const { isDesktop } = useBreakpoints();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const showDesc = isDesktop || !isSubmitted;

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isSubmitted) {
      timerId = setTimeout(() => setIsSubmitted(false), REQUEST_INTERVAL);
    }

    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <CallbackFormWrapper
      id='callback-form'
      direction={{ xs: 'column', lg: 'row' }}
      columnGap='64px'
      alignItems='center'
      rowGap='16px'
      justifyContent='space-between'
    >
      <Stack direction='column' rowGap='32px' alignItems={{ xs: 'center', lg: 'start' }}>
        {showDesc && (
          <Stack
            direction='column'
            alignItems={{ xs: 'center', lg: 'start' }}
            rowGap={{ xs: '16px', md: '8px', lg: '20px' }}
            textAlign={{ xs: 'center', lg: 'start' }}
          >
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Stack>
        )}

        {isDesktop && <img width='313px' height='188px' src={CarImage} />}
      </Stack>

      {isSubmitted ? <Success /> : <Form car={car} onSubmit={() => setIsSubmitted(true)} />}
    </CallbackFormWrapper>
  );
};
