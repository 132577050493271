import { FC } from 'react';

import { Box, Button } from '@mui/material';

import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';
import { scrollToCallbackForm } from '~/utils';

import { CarInfoSkeleton } from './CarInfo.skeleton';
import { Title } from './CarInfo.styles';
import { PurchaseInfo, SubscriptionInfo } from './components';

type Props = {
  car?: TCar;
};
export const CarInfo: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();
  if (!car) {
    return <CarInfoSkeleton />;
  }

  return (
    <Box>
      <Title marginBottom={{ xs: '4px', md: '8px', lg: '16px' }}>
        {`${car.name} ${car.complectation_name}`}
      </Title>
      {isSubscription ? <SubscriptionInfo car={car} /> : <PurchaseInfo car={car} />}
      <Button variant='contained' size='large' fullWidth onClick={scrollToCallbackForm}>
        Оставить заявку
      </Button>
    </Box>
  );
};
