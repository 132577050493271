import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { useIsSubscription } from 'hooks';
import { TCar } from 'types';

type Props = {
  car?: TCar;
};
/** Динамические параметры SEO оптимизации. Меняются от выбранной машины и режима подписки/покупки */
export const Seo: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  const baseUrl = 'https://vtb-auto.ru';

  var title = `ВТБ Авто / ${isSubscription ? 'Подписка' : 'Покупка'}`;
  if (car) {
    title += ` / ${car?.name}`;
  }

  const description = car
    ? car.metaDescription
    : 'ВТБ Авто - онлайн-сервис по покупке и подписке на автомобили. Новые и с пробегом. Покупка с оплатой частями или в кредит. Популярные марки из Европы, Японии и других стран.';
  const imageUrl = car?.images[0] ? car?.images[0] : `${baseUrl}/assets/images/og-image.webp`;

  return (
    <Helmet>
      {/* Название сайта */}
      <title>{title}</title>
      {/* Каноничный адрес страницы (при наличии дублей) */}
      <link rel='canonical' href={window.location.href} />
      {/* Ключевые слова */}
      <meta name='description' content={description} />
      {/* Open Graph разметка */}
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={window.location.href} />
      {imageUrl && <meta property='og:image' content={imageUrl} />}
      {imageUrl && <meta property='vk:image' content={imageUrl} />}
      {imageUrl && <meta property='fb:image' content={imageUrl} />}
      {imageUrl && <meta property='twitter:image' content={imageUrl} />}
    </Helmet>
  );
};
