import {Box, Typography} from '@mui/material';

import styled from '@emotion/styled';

export const IconContainer = styled(Box)`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${({theme}) => theme.palette.vtbAuto.BlueAccent};
  width: 16px;
  height: 16px;

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }
`;

export const Item = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueDark};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 16px;
    line-height: 18px;
  }

  & svg {
    margin-right: 8px;
  }
`;
