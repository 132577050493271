import type { FC, ReactNode } from 'react';

import { Stack } from '@mui/material';

import { ReactComponent as ExcludeIcon } from 'assets/icons/ckeck-mark-solid.svg';

import { IconContainer, Item, Title } from './CarDescription.styles';

type Props = {
  header?: ReactNode;
  items: ReactNode[];
};
export const CarDescription: FC<Props> = ({ header, items }) => {
  return (
    <Stack rowGap={{ xs: '12px', lg: '16px' }} marginBottom={{ xs: '16px', lg: '24px' }}>
      {header && <Title>{header}</Title>}

      <Stack rowGap={{ xs: '6px', md: '8px', lg: '12px' }}>
        {items.map((value, index) => (
          <Stack flexDirection='row' key={index} alignItems='top' columnGap='8px'>
            <IconContainer>
              <ExcludeIcon />
            </IconContainer>
            <Item>{value}</Item>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
