import { useMatch, useSearchParams } from 'react-router-dom';

import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { routeConfig } from '~/app/routeConfig';
import { useIsSubscription } from '~/hooks';
import { useQueryStringParam } from '~/modules/Catalog/useQueryParams';
import { bff } from '~/services/API';
import { DEFAULT_FILTERS, PARAM_NAMES } from '~/services/constants';
import { swrMiddleware } from '~/utils';

export const useAllTags = () => useSWRImmutable('allTags', () => bff.getAllTags());

export const useAllBrandsAndModels = () => {
  const tag = useQueryStringParam(PARAM_NAMES.Tag, '');
  const isSubscription = useIsSubscription();
  return useSWR(['allBrandsAndModels', tag], () =>
    bff.getAllBrandsAndModels({ newTags: [tag], isSubscription }),
  );
};

export const useRelatedCars = ({
  carId,
  carType,
}: {
  carId?: string;
  carType: Parameters<typeof bff.getRelatedCars>[1];
}) => {
  return useSWR(
    carId ? ['relatedCars', carId, carType] : null,
    ([, id, type]) => bff.getRelatedCars(id, type),
    {
      use: [swrMiddleware],
    },
  );
};

export const useSearchCars = () => {
  const [urlSearchParams] = useSearchParams();
  const isSubscriptionPage = useMatch(routeConfig.SubscriptionCatalogue.path);
  const params = {} as Record<PARAM_NAMES, unknown>;

  for (const [key, value] of urlSearchParams) {
    if (!(key in DEFAULT_FILTERS) || !value) {
      continue;
    }

    if (params[key]) {
      params[key] = [params[key], value].flat();
    } else {
      params[key] = value;
    }
  }

  [PARAM_NAMES.Brands, PARAM_NAMES.Models].forEach((key) => {
    if (key in params) {
      params[key] = [params[key]].flat();
    }
  });

  return useSWR(
    [
      'searchCars',
      {
        ...params,
        newTags: params.tags && [params.tags].flat(),
        ...(isSubscriptionPage ? { for_subscription: true } : { for_sale: true }),
        ...(params.sort_direction ? { sort_by: isSubscriptionPage ? 'commonPrice' : 'price' } : {}),
      },
    ],
    ([, params]) => bff.searchCars(params),
    {
      use: [swrMiddleware],
    },
  );
};

export const useCar = (id?: string) => {
  return useSWR(id ? ['car', id] : null, ([, carId]) => bff.getCar(carId));
};
