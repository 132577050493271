import { FC, useState } from 'react';
import { Settings } from 'react-slick';
import type SlickSlider from 'react-slick';

import NoCar from 'assets/images/no-car.webp';
import { Slider } from 'components';
import { useBreakpoints } from 'hooks';
import { TCar } from 'types';

import { CarSliderSkeleton } from './CarSlider.skeleton';
import {
  BottomSlideImage,
  SliderContainer,
  TopSlideImage,
  TopSliderContainer,
} from './CarSlider.styles';
import { SliderArrow, SliderTags } from './components';

const BOTTOM_SLIDES_LIMIT = 6;

type Props = {
  car?: TCar;
};
export const CarSlider: FC<Props> = ({ car }) => {
  const { isDesktop, isMobile } = useBreakpoints();

  const [topSlider, setTopSlider] = useState<SlickSlider | null>(null);
  const [bottomSlider, setBottomSlider] = useState<SlickSlider | null>(null);

  if (!car) {
    return <CarSliderSkeleton />;
  }

  const images = Array.isArray(car.images) && car.images.length ? car.images : [NoCar];

  const topSliderSettings: Settings = {
    slidesToShow: 1,
    dots: !isDesktop,
    arrows: !isMobile,
    infinite: false,
    nextArrow: <SliderArrow type='next' size='large' />,
    prevArrow: <SliderArrow type='prev' size='large' />,
  };

  const bottomSliderSettings: Settings = {
    dots: false,
    slidesToShow: images.length > BOTTOM_SLIDES_LIMIT ? BOTTOM_SLIDES_LIMIT : images.length,
    arrows: images.length > BOTTOM_SLIDES_LIMIT,
    infinite: false,
    nextArrow: <SliderArrow type='next' size='small' />,
    prevArrow: <SliderArrow type='prev' size='small' />,
    focusOnSelect: true,
    variableWidth: true,
  };

  return (
    <>
      <TopSliderContainer>
        <Slider {...topSliderSettings} ref={setTopSlider} asNavFor={bottomSlider || undefined}>
          {images.map((url, idx) => (
            <TopSlideImage key={idx} url={url} />
          ))}
        </Slider>
        <SliderTags car={car} />
      </TopSliderContainer>

      {isDesktop && (
        <SliderContainer>
          <Slider {...bottomSliderSettings} ref={setBottomSlider} asNavFor={topSlider || undefined}>
            {images.map((url, idx) => (
              <div key={idx}>
                <BottomSlideImage url={url} />
              </div>
            ))}
          </Slider>
        </SliderContainer>
      )}
    </>
  );
};
