import { Box, Button, Stack } from "@mui/material";

import { TCarTagColor } from "types";

import styled from "@emotion/styled";

export const Wrapper = styled(Stack)`
  flex-direction: column;

  .MuiPagination-root {
    margin: 16px auto 0;

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-top: 32px;
    }
  }
`;

export const InputsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12px;
  row-gap: 16px;

  > * {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-flow: row wrap;
    column-gap: 16px;
    padding-top: 16px;

    > * {
      width: calc(100% / 3 - 11px);
    }

    .MuiButton-root {
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-flow: row nowrap;
    column-gap: 32px;
    padding-top: 24px;

    > *,
    .MuiButton-root {
      width: 296px;
    }
  }
`;

export const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  column-gap: 8px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: 16px;
    column-gap: 22px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 24px;
  }
`;

export const ButtonStyled = styled(Button)<{ colorToken: TCarTagColor }>`
  && {
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.5px;
    padding: 16px;

    &.MuiButton-containedPrimary {
      background: ${({ theme, colorToken }) => {
        switch (colorToken) {
          case "blue":
            return theme.palette.vtbAuto.GradientPrimaryBlueViolet;
          case "green":
            return theme.palette.vtbAuto.GradientGreen;
          case "purple":
            return theme.palette.vtbAuto.GradientPurple;
        }
      }}!important;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      padding: 6px 10px;
    }
  }
`;
