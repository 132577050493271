import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

export const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.vtbAuto.BluePrimary};
  fill: ${({theme}) => theme.palette.common.white};
  width: 32px;
  height: 32px;

  & svg {
    width: 20px;
    height: 20px;
  }

  ${({theme}) => theme.breakpoints.up('sm')} {
    width: 40px;
    height: 40px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Title = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
`;

export const Text = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.common.black};
`;
