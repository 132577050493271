export enum PARAM_NAMES {
  SortDirection = 'sort_direction',
  Brands = 'mark_id',
  Models = 'folder_id',
  Tag = 'tags',
  Page = 'page',
  PerPage = 'per_page',
}

export type QuerySearchParams = Readonly<{
  [PARAM_NAMES.SortDirection]?: '' | 'asc' | 'desc';
  [PARAM_NAMES.Brands]?: string[];
  [PARAM_NAMES.Models]?: string[];
  [PARAM_NAMES.Tag]?: string;
  [PARAM_NAMES.Page]?: string;
  [PARAM_NAMES.PerPage]: string;
}>;

export type Param = keyof QuerySearchParams;

export const PER_PAGE_NUMBER = 15;

export const DEFAULT_FILTERS: QuerySearchParams = {
  [PARAM_NAMES.SortDirection]: undefined,
  [PARAM_NAMES.Page]: '1',
  [PARAM_NAMES.PerPage]: PER_PAGE_NUMBER.toString(),
  [PARAM_NAMES.Tag]: undefined,
  [PARAM_NAMES.Brands]: undefined,
  [PARAM_NAMES.Models]: undefined,
} as const;
