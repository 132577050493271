import {Accordion, Typography} from '@mui/material';

import styled from '@emotion/styled';

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1px;
  color: ${({theme}) => theme.palette.vtbAuto.BlueAccent};
  margin-bottom: 16px;

  ${({theme}) => theme.breakpoints.up('sm')} {
    margin-bottom: 8px;
  }
`;

export const Text = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
`;

export const AccordionStyled = styled(Accordion)`
  background: transparent;
  color: ${({theme}) => theme.palette.vtbAuto.BlueDark};

  &:not(:first-of-type)::before {
    display: block!important;
    opacity: 1 !important;
  }
`;
