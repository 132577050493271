import type { ThemeOptions } from '@mui/material';

export const linkOptions: ThemeOptions = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.vtbl.BlueSky,
        }),
      },
    },
  },
};
