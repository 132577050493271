import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Container, Typography } from "@mui/material";

import PurchaseBannerXl from "assets/images/purchase-banner-xl.webp";
import PurchaseBannerLg from "assets/images/purchase-banner-lg.webp";
import PurchaseBannerMd from "assets/images/purchase-banner-md.webp";
import PurchaseBannerSm from "assets/images/purchase-banner-sm.webp";
import SubscriptionBannerXl from "assets/images/subscription-banner-xl.webp";
import SubscriptionBannerLg from "assets/images/subscription-banner-lg.webp";
import SubscriptionBannerMd from "assets/images/subscription-banner-md.webp";
import SubscriptionBannerSm from "assets/images/subscription-banner-sm.webp";

export const BannerContainer = styled.section<{
  isSubscription: boolean;
  isPurchase: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  height: 450px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 0px 0px 12px 12px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    background-position: 100% 10%;
    height: 320px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    background-position: 100% 10%;
    height: 450px;
  }

  ${({ theme, isSubscription }) =>
    isSubscription &&
    `
      background-image: url(${SubscriptionBannerSm});

      ${theme.breakpoints.up("sm")} {
        border-radius: 0;
        background-image: url(${SubscriptionBannerMd});
      }

      ${theme.breakpoints.up("lg")} {
        background-image: url(${SubscriptionBannerLg});
      }
     
      ${theme.breakpoints.up("xl")} {
        background-image: url(${SubscriptionBannerXl});
      }
  `}

  ${({ theme, isPurchase }) =>
    isPurchase &&
    `
      background-image: url(${PurchaseBannerSm});

      ${theme.breakpoints.up("sm")} {
        border-radius: 0;
        background-image: url(${PurchaseBannerMd});
      }

      ${theme.breakpoints.up("lg")} {
        background-image: url(${PurchaseBannerLg});
      }
      
      ${theme.breakpoints.up("xl")} {
        background-image: url(${PurchaseBannerXl});
      }
  `}
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 122px;
    padding-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 122px;
    padding-bottom: 32px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-weight: 500;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
  }
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
`;

export const LinkTitle = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.75px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
  }
`;

export const StyledLink = styled(NavLink)<{
  left?: boolean;
  right?: boolean;
}>`
  padding: 12px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  flex-grow: 1;

  ${({ left }) =>
    left &&
    css`
      border-radius: 12px 0 0 12px;
    `}

  ${({ right }) =>
    right &&
    css`
      border-radius: 0 12px 12px 0;
    `}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    text-align: inherit;
  }

  &.active {
    cursor: default;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 20px 28px;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.common.white};

    > :last-of-type {
      color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
    }

    > :first-of-type {
      color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
    }
  }
`;
