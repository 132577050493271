import {Chip, styled, Typography} from "@mui/material";

export const Vin = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.5px;
  color: ${({theme}) => theme.palette.vtbAuto.DarkGray};

  & span:first-of-type {
    color: ${({theme}) => theme.palette.vtbAuto.PrimaryGray};
  }
`;
