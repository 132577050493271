import { Box, Link, Stack } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_LABEL,
  TELEGRAM_URL,
  WHATSAPP_URL,
} from "consts";
import { useBreakpoints } from "hooks";

import {
  FooterButton,
  FooterLogo,
  FooterTypography,
  FooterTypographyInfo,
  MailLink,
  StyledContainer,
  StyledFooter,
} from "./Footer.styles";

export const Footer = () => {
  const { isMobile } = useBreakpoints();

  return (
    <StyledFooter component={"footer"}>
      <StyledContainer>
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          justifyContent={isMobile ? "stretch" : "space-between"}
          rowGap="8px"
        >
          <Box>
            {!isMobile && <FooterLogo />}

            <Link
              href={`tel:${SUPPORT_PHONE}`}
              underline="none"
              variant="h3"
              color={(theme) => theme.palette.vtbl.DarkGrey}
              lineHeight={"inherit"}
            >
              {SUPPORT_PHONE_LABEL}
            </Link>

            <FooterTypography variant="body2">
              Мы на связи с 9:00 до 20:00
            </FooterTypography>

            <MailLink href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </MailLink>

            <FooterTypography variant="body2">
              Техническая поддержка
            </FooterTypography>
          </Box>

          <Stack direction="column" rowGap="8px" marginBottom="32px">
            <FooterButton
              href={TELEGRAM_URL}
              variant="contained"
              size={isMobile ? "medium" : "small"}
              startIcon={<TelegramIcon />}
            >
              Помощник в Телеграме
            </FooterButton>
            <FooterButton
              href={WHATSAPP_URL}
              variant="contained"
              size={isMobile ? "medium" : "small"}
              startIcon={<WhatsAppIcon />}
            >
              Помощник в WhatsApp
            </FooterButton>
          </Stack>
        </Stack>
        <FooterTypographyInfo mb={"24px"}>
          Услуги предоставляются ООО «УКА» (ИНН 7702827041).
        </FooterTypographyInfo>
        <FooterTypographyInfo mb={"24px"}>
          Обращаем ваше внимание, что вся информация, размещенная на сайте,
          носит информационный характер и не является публичной офертой,
          определяемой положениями статьи 437 Гражданского кодекса Российской
          Федерации. ООО «УКА» оставляет за собой право в одностороннем порядке
          в любое время без уведомления вносить изменения, удалять, исправлять,
          дополнять, либо иным способом обновлять информацию об условиях
          программы. Конкретные условия договора будут определяться отдельно при
          его заключении.
        </FooterTypographyInfo>
        <FooterTypographyInfo mb={"24px"}>
          Ни ООО «УКА», ни его сотрудники не несут ответственности за убытки,
          возникшие в результате использования информации, содержащейся на
          сайте, в инвестиционных или в иных целях, а также за прямые или
          косвенные убытки, понесенные в результате неисправностей или перерывов
          в работе сайта. Продавец либо Арендодатель: ООО «УКА», ОГРН
          5137746118252, ИНН 7702827041, адрес: Москва. Входит в Группу ВТБ.
        </FooterTypographyInfo>
        <FooterTypographyInfo>
          Для повышения удобства работы с сайтом ООО «УКА» использует файлы
          Cookie. Оставаясь на сайте, вы соглашаетесь{" "}
          <Link
            sx={{
              color: (theme) => theme.palette.vtbAuto.BluePrimary,
              textDecoration: "auto",
            }}
            href="/assets/files/politika-cookie.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            с Политикой использования Cookies
          </Link>
          . Если вы не хотите, чтобы Cookie-данные обрабатывались, отключите их
          в настройках браузера.
        </FooterTypographyInfo>
      </StyledContainer>
    </StyledFooter>
  );
};
