import { Link } from "react-router-dom";
import { Box, BoxProps } from "@mui/material";

import VtbAutoLogo from "assets/images/vtb-auto-logo.webp";
import {routeConfig} from "~/app/routeConfig";

export const Logo = ({ sx, ...props }: BoxProps) => {
  return (
    <Link to={routeConfig.PurchaseCatalogue.path}>
      <Box
        component={"img"}
        width={"136px"}
        src={VtbAutoLogo}
        alt="VTB Auto Logo"
        sx={{ cursor: "pointer", ...sx }}
        {...props}
      />
    </Link>
  );
};
