import type { PropsWithChildren } from "react";
import {useCallback, useEffect, useMemo, useRef} from "react";

import { Button, MenuItem, Pagination, Select } from "@mui/material";

import { AutoComplete } from "~/components/FormControls/AutoComplete";
import { useBreakpoints } from "~/hooks/useBreakpoints";
import { useIsSubscription } from "~/hooks/useIsSubscription";
import {
  useAllBrandsAndModels,
  useAllTags,
  useSearchCars,
} from "~/queries/queries";
import { DEFAULT_FILTERS, PARAM_NAMES } from "~/services/constants";

import { TagsSceleton } from "../Sceletons";
import {
  useQueryArrayParam,
  useQueryNumberParam,
  useQueryStringParam,
  useSetQueryParams,
} from "../useQueryParams";
import {
  ButtonStyled,
  InputsWrapper,
  TagsWrapper,
  Wrapper,
} from "./Filters.styles";

export const Filters = ({ children }: PropsWithChildren) => {
  const { isMobile } = useBreakpoints();
  const isSubscibtion = useIsSubscription()
  const filtersWrapperRef = useRef<HTMLDivElement | null>(null);
  const { data: tags, isValidating: isAllTagsValidating } = useAllTags();
  const {
    data: availableBrands,
    isValidating: isAllBrandsAndModelsValidating,
    mutate,
  } = useAllBrandsAndModels();
  const { data: searchCars } = useSearchCars();
  const isSubscription = useIsSubscription();
  const brands = useQueryArrayParam(PARAM_NAMES.Brands, []);
  const models = useQueryArrayParam(PARAM_NAMES.Models, []);
  const tag = useQueryStringParam(PARAM_NAMES.Tag, "");
  const page = useQueryNumberParam(PARAM_NAMES.Page, 1);
  const sortDirection = useQueryStringParam(PARAM_NAMES.SortDirection, "");
  const setQueryParams = useSetQueryParams();

  useEffect(() => {
    mutate()
  }, [isSubscibtion])

  const brandOptions = useMemo(
    () => availableBrands?.map(({ name }) => name) ?? [],
    [availableBrands]
  );

  const getModelOptions = useCallback(
    (fetchedBrands: typeof availableBrands, selectedBrands: typeof brands) => {
      return (
        fetchedBrands?.reduce((acc, brand) => {
          if (selectedBrands.includes(brand.name)) {
            return [...acc, ...brand.models.map(({ name }) => name)];
          }
          return acc;
        }, [] as string[]) ?? []
      );
    },
    []
  );

  const modelOptions = useMemo(
    () => getModelOptions(availableBrands, brands),
    [brands, availableBrands, getModelOptions]
  );

  const selectSize = isMobile ? "medium" : "small";

  const handleSelectBrands = (values: typeof brands) => {
    const availableModelOptions = getModelOptions(availableBrands, values);
    const selectedModels = models.filter((_) =>
      availableModelOptions.includes(_)
    );
    setQueryParams({
      [PARAM_NAMES.Brands]: values,
      [PARAM_NAMES.Models]: selectedModels,
      [PARAM_NAMES.Page]: "1",
    });
  };

  const handleResetFilters = () => {
    setQueryParams({ ...DEFAULT_FILTERS });
  };

  const showPagination = searchCars && searchCars.last_page > 1;

  return (
    <Wrapper ref={filtersWrapperRef}>
      {!isSubscription && (
        <TagsWrapper>
          {isAllTagsValidating && <TagsSceleton />}

          {Array.isArray(tags) &&
            tags.map(({ label, color }) => (
              <ButtonStyled
                variant="contained"
                color={
                  label.toLowerCase() === tag.toLowerCase()
                    ? "primary"
                    : "secondary"
                }
                colorToken={color}
                size={isMobile ? "large" : "medium"}
                key={label}
                sx={{
                  flex: isMobile ? "1 0 0!important" : undefined,
                }}
                onClick={() =>
                  setQueryParams({
                    [PARAM_NAMES.Tag]: label === tag ? "" : label,
                    [PARAM_NAMES.Page]: "1",
                  })
                }
              >
                {label}
              </ButtonStyled>
            ))}
        </TagsWrapper>
      )}
      <InputsWrapper>
        <AutoComplete
          value={brands}
          options={brandOptions}
          size={selectSize}
          TextFieldProps={{ placeholder: "Марка" }}
          onChange={(_, values) => handleSelectBrands(values)}
          disabled={isAllBrandsAndModelsValidating}
        />
        <AutoComplete
          value={models}
          options={modelOptions}
          size={selectSize}
          TextFieldProps={{ placeholder: "Модель" }}
          onChange={(_, values) =>
            setQueryParams({
              [PARAM_NAMES.Models]: values,
              [PARAM_NAMES.Page]: "1",
            })
          }
          disabled={isAllBrandsAndModelsValidating}
        />
        <Select
          value={sortDirection}
          size={selectSize}
          displayEmpty
          onChange={({ target: { value } }) =>
            setQueryParams({
              [PARAM_NAMES.SortDirection]: value as "asc" | "desc",
              [PARAM_NAMES.Page]: "1",
            })
          }
        >
          <MenuItem disabled value="">
            Сортировка
          </MenuItem>
          <MenuItem value="asc">Сначала дешевые</MenuItem>
          <MenuItem value="desc">Сначала дорогие</MenuItem>
        </Select>
        <Button
          variant="outlined"
          size={isMobile ? "large" : "medium"}
          onClick={handleResetFilters}
        >
          Сбросить фильтр
        </Button>
      </InputsWrapper>

      {children}

      {showPagination && (
        <Pagination
          variant="text"
          shape="circular"
          size={isMobile ? "large" : "medium"}
          count={searchCars?.last_page}
          page={page}
          onChange={(_, newPage) => {
            filtersWrapperRef.current?.scrollIntoView();
            setQueryParams({ [PARAM_NAMES.Page]: newPage.toString() });
          }}
          boundaryCount={isMobile ? 0 : 1}
          disabled={!searchCars?.last_page}
        />
      )}
    </Wrapper>
  );
};
