import { Accordion, Tooltip, tooltipClasses, Typography } from "@mui/material";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    column-gap: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }
`;

export const Description = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
`;

export const Card = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  border-radius: 12px;
  padding: 20px 20px 18px 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;

  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      border: 1px solid ${theme.palette.vtbAuto.BluePrimary};
    `}
`;

export const DetailsText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;

export const DetailsIcon = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 35%;
`;

export const ImageWrapper = styled.img`
  max-height: 320px;
  max-width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 65%;
  padding: 0 32px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 12px !important;
    padding: 4px 0;
    border: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.common.white};
    &::before {
      display: none;
    }
  }
`;
