import { darken, type ThemeOptions } from '@mui/material';

import { css } from '@emotion/react';

export const textfieldOptions: ThemeOptions = {
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => css`
          color: ${theme.palette.vtbAuto.DarkGray};
          background: ${theme.palette.common.white};
          height: 56px;

          &&.Mui-disabled {
            background: ${darken(theme.palette.common.white, 0.1)};
          }

          &&.MuiInputBase-sizeSmall {
            padding: 8px 6px 8px 6px;
            height: 42px;
          }
        `,
        input: ({ theme }) => css`
          && {
            font-size: 18px;
            line-height: 21px;
            height: 21px;
            letter-spacing: -0.75px;

            &::placeholder {
              color: ${theme.palette.vtbl.Silver};
              opacity: 0.5;
            }
          }
        `,
        inputSizeSmall: () => css`
          && {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.5px;
            padding: 2.5px 4px 2.5px 8px;
          }
        `,
        notchedOutline: ({ theme }) => css`
          && {
            border-color: ${theme.palette.vtbl.HawaiianSky};

            .MuiOutlinedInput-root.Mui-focused & {
              border-width: 1px;
              border-color: ${theme.palette.vtbAuto.BluePrimary};
            }

            .MuiOutlinedInput-root:hover:not(.Mui-focused, .Mui-error, .Mui-disabled) & {
              border-color: ${theme.palette.vtbAuto.BluePrimary};
            }
          }
        `,
      },
      defaultProps: {
        notched: false,
      },
    },
  },
};
