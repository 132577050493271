import { FC } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { NextArrow, PrevArrow } from './SliderArrow.styles';

type Props = {
  onClick?: () => void;
  size: 'small' | 'large';
  type: 'next' | 'prev';
};
export const SliderArrow: FC<Props> = ({ onClick, type, size }) => {
  const Arrow = type === 'next' ? NextArrow : PrevArrow;

  return (
    <Arrow onClick={onClick} size={size}>
      <ArrowForwardIosIcon />
    </Arrow>
  );
};
