import type { CSSProperties } from 'react';

import { createTheme } from '@mui/material';
import type { Palette, ThemeOptions } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { deepmerge } from '@mui/utils';

import { type Theme as MuiKitTheme, VtblColors } from '@vtbl/mui-kit';

import { fontFaceRegular } from '../fonts/fonts.styles';
import { VtbAutoColors } from './colors';
import { autocompleteOptions } from './themeOptions/autocomplete';
import { buttonOptions } from './themeOptions/button';
import { linkOptions } from './themeOptions/link';
import { menuItem } from './themeOptions/menuItem';
import { selectOptions } from './themeOptions/select';
import { textfieldOptions } from './themeOptions/textfield';
import { typographyOptions } from './themeOptions/typography';
import { accordionOptions } from './themeOptions/accordion';
import { tooltipOptions } from './themeOptions/tooltip';

export type Theme = MuiKitTheme & {
  palette: Palette & { vtbAuto: typeof VtbAutoColors };
  typography: TypographyOptions & { body3: CSSProperties; mainTextDes: CSSProperties };
};

declare module '@mui/material' {
  interface Palette {
    vtbAuto: typeof VtbAutoColors;
  }
  interface PaletteOptions {
    vtbAuto?: typeof VtbAutoColors;
  }
}

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    mainTextDes: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    mainTextDes?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    mainTextDes: true;
  }
}

declare module '@mui/material/Accordion' {
  interface AccordionPropsVariantOverrides {
    secondary: true;
  }
}

const themeOptions = [
  // accordionOptions,
  buttonOptions,
  typographyOptions,
  linkOptions,
  textfieldOptions,
  autocompleteOptions,
  selectOptions,
  tooltipOptions,
  menuItem,
].reduce((acc, option) => {
  return deepmerge(acc, option);
}, {} as ThemeOptions);

const vtbAutoTheme = deepmerge(
  {
    palette: {
      vtbAuto: VtbAutoColors,
      primary: {
        main: VtbAutoColors.BluePrimary,
        contrastText: '#fff',
      },
      error: {
        main: VtblColors.ThimbleberrySmart,
        dark: VtblColors.ArtfulRed,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `${fontFaceRegular}`,
      },
    },
  },
  themeOptions,
);

export const mergeNestedTheme = (theme: MuiKitTheme) => deepmerge(theme, createTheme(vtbAutoTheme));
