import { useCallback } from 'react';

import { useSnackbar } from 'material-ui-snackbar-provider';

/**
 * Hook to get util function
 * @returns copy to clipboad function
 */
export const useCopyToClipboard = () => {
  const snackbar = useSnackbar();

  return useCallback(
    async (text: string, successMessage: string) => {
      try {
        await navigator.clipboard.writeText(text);
        snackbar.showMessage(successMessage, 'success');
      } catch (error) {
        console.log(error);
      }
    },
    [snackbar],
  );
};
