import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeOptions } from "@mui/material";
import { css } from "@emotion/react";

export const selectOptions: ThemeOptions = {
  components: {
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => css`
          && .MuiSelect-icon {
            color: ${theme.palette.vtbl.HawaiianSky};
          }
        `,
        nativeInput: ({ theme }) => css`
          &::placeholder {
            color: ${theme.palette.vtbAuto.DarkGray};
            opacity: 1;
          }
        `,
        select: () => css`
          && {
            display: flex;
            align-items: center;
          }
        `,
      },
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
  },
};
