import { forwardRef, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { Box } from '@mui/material';

import { getCarOptions } from 'utils/getCarOptions';
import { separateThousands } from 'utils/separateThousands';

import { routeConfig } from '~/app/routeConfig';
import { CarTag } from '~/components';
import { useIsSubscription } from '~/hooks';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { TCar } from '~/types';

import WithoutPhoto from '../Without_photo.png';
import {
  ButtonStyled,
  Complectation,
  ContentWrapper,
  ImageWrapper,
  Options,
  SlideImage,
  StyledSlider,
  StyledTagsStack,
  Title,
  Wrapper,
} from './Card.styles';
import { Price } from './components';

const mobileHeightPercent = 0.635;
const tabletHeightPercent = 0.621;
const desktopHeightPercent = 0.824;
export const cardContentWrapperClassName = 'card-content-wrapper';

type Props = {
  car: TCar;
  isRelated?: boolean;
  width: number;
  size?: 'small' | 'medium' | 'large';
  tagToShow?: string;
  showSelfTag?: boolean;
};

export const Card = forwardRef<HTMLAnchorElement, Props>(
  (
    { car, isRelated = false, width, size = 'medium', tagToShow = '', showSelfTag = false },
    ref,
  ) => {
    const { isTablet, isDesktop, isMobile } = useBreakpoints();
    const isSubscription = useIsSubscription();
    // умножение на соотношение высота / ширина картинки в соответствии с макетами
    const height = useMemo(() => {
      const getHeight = (percent) => width * percent;
      if (isTablet) {
        return getHeight(tabletHeightPercent);
      }

      if (isDesktop) {
        return getHeight(desktopHeightPercent);
      }

      return getHeight(mobileHeightPercent);
    }, [width, isDesktop, isTablet]);

    const tag = useMemo(() => {
      if (isSubscription) {
        return null;
      }

      if (Array.isArray(car.newTags) && car.newTags.length > 0) {
        if (tagToShow && car.newTags.some((tag) => tag.label == tagToShow)) {
          return car.newTags.find((tag) => tag.label == tagToShow);
        }

        if (showSelfTag) {
          return car.newTags[0];
        }
      }

      return null;
    }, [tagToShow, car.newTags, showSelfTag, isSubscription]);

    const maxVisibleSlidesCount = useMemo(() => {
      if (isMobile && size === 'small') {
        return 1;
      }

      return 5;
    }, [size, isMobile]);

    const isUsed = car.owners_number > 0;
    const showOutOfStockTag = car.count === 0 && !isRelated;
    const showSpecialOfferTag = car.special_offer && !isRelated;
    const path = generatePath(
      `/${isSubscription ? routeConfig.SubscriptionCard.path : routeConfig.PurchaseCard.path}`,
      { id: car.id } as any,
    );

    return (
      <Wrapper to={path} className={size === 'small' ? 'card-small' : undefined} ref={ref}>
        <Box width={`${width}px`}>
          {Array.isArray(car.images) && (
            <StyledSlider enableMouseMoveSlide lazyLoad='ondemand' fade waitForAnimate={false}>
              {car.images.slice(0, maxVisibleSlidesCount).map((url, idx) => (
                <ImageWrapper key={idx} sx={{ height: height + 'px', width: width + 'px' }}>
                  <SlideImageWrapper src={url} />
                </ImageWrapper>
              ))}
            </StyledSlider>
          )}
        </Box>

        <ContentWrapper className='card-content-wrapper'>
          <Price car={car} />
          <Title>{car.name}</Title>
          <Complectation>{car.complectation_name}</Complectation>
          <Options>
            {getCarOptions(
              isUsed
                ? [
                    car.registry_year.toString(),
                    car.mileage ? separateThousands(car.mileage) + ' км' : '',
                    car.engine_type,
                    car.power_hp + ' л.с.',
                    car.engine + ' л.',
                    car.gearbox,
                  ]
                : [
                    car.registry_year.toString(),
                    car.engine_type,
                    car.power_hp + ' л.с.',
                    car.engine + ' л.',
                    car.gearbox,
                    car.drive,
                    car.color,
                  ],
              3,
            )}
          </Options>
          {isDesktop && (
            <ButtonStyled size='medium' variant='contained'>
              Подробнее
            </ButtonStyled>
          )}
        </ContentWrapper>
        <StyledTagsStack direction='row'>
          {tag && <CarTag label={tag.label} color={tag.color} />}
          {showOutOfStockTag && <CarTag label='Нет в наличии' color='black' />}
          {showSpecialOfferTag && <CarTag label='🔥' color='white' />}
        </StyledTagsStack>
      </Wrapper>
    );
  },
);

Card.displayName = 'Card';

const SlideImageWrapper = ({ src }: { src: string }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = (e) => {
    e.stopPropagation();
    setImgSrc(WithoutPhoto);
  };

  return (
    <SlideImage src={imgSrc} onError={handleError} width={imgSrc !== src ? '100%' : undefined} />
  );
};
