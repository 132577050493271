import { Chip } from "@mui/material";
import styled from "@emotion/styled";

import { TCarTagColor } from "types";

export const Tag = styled(Chip)<{ colorToken: TCarTagColor }>`
  height: 18px;

  & .MuiChip-label {
    padding: 4px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
  }

  &.MuiChip-colorDefault {
    background: ${({theme, colorToken}) => {
      switch (colorToken) {
        case "blue":
          return theme.palette.vtbAuto.GradientPrimaryBlueViolet;
        case "green":
          return theme.palette.vtbAuto.GradientGreen;
        case "purple":
          return theme.palette.vtbAuto.GradientPurple;
        case "white":
          return theme.palette.common.white;
        case "black":
        default:
          return theme.palette.vtbAuto.DarkGray;
      }
    }} !important;
  }
`;
